import React from 'react';
import "../../Css/Store.scss";

const AdCard = ({ imageUrl }) => {
    return (
        // <div className="max-w-[730px] w-full flex justify-center">
        //     <div className="ad-card p-4 mt-2 mb-2 rounded-lg w-full">
        //         <img
        //             src={imageUrl}
        //             alt="Ad"
        //             className="w-full h-auto md:h-48 object-cover border rounded-3xl"
        //         />
        //     </div>
        // </div>

        <div className="ad-container">
            <div className="ad-card">
                <img
                    src={imageUrl}
                    alt="Ad"
                    className="ad-image"
                />
            </div>
        </div>

    );
};

export default AdCard;
