import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const Transaction = {
  /*----------------------Transaction---------------------------------------------------------------------*/

  GetTransRefNoForOrder: async (userArray, cartData) => {
    const data = [{
      refNoData: {
        referenceNumber: "",
        transtype: 12,
        transId: 0,
      },
      userData: userArray,
      cartData:cartData
    }]
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetTransRefNoForOrder`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        data,
        {
          withCredentials: true,
          credentials: "include",
        }
      );

      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  // ***************************************************************************
  SetTransBillData: async (data, InputValueAccMaster, ReferenceNumber) => {
    // debugger;
    console.log(InputValueAccMaster);

    // console.log(Id);
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/SetTransBillData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          transtype: Number(data.TransType) || 0,
          billTypeId: Number(data.billTaxTypeId) || 0,
          billSeriesId: Number(data.billSeriesId) || 0,
          billDate: data.billDate ? new Date(data.billDate).toISOString() : "",
          billNumber: data.billNumber || "",
          godownId: Number(data.godownId) || 0,
          bookedById: Number(data.bookedById) || 0,
          partyId: Number(InputValueAccMaster.AccId) || 0,
          refById: Number(InputValueAccMaster.AccRefById) || 0,
          stateOfSale: Number(data.stateOfSale) || 0,
          refBillNumber: data.refBillNumber || "",
          refBillDate: data.refBillDate ? new Date(data.refBillDate).toISOString() : "",
          DeliveryDate: data.DeliveryDate ? new Date(data.DeliveryDate).toISOString() : "",
          billStatus: Number(data.vochStatusId) || 0,
          billRemark: String(data.Remark),
          billRemark1: [Number(data.Remark1)] || [0],
          billRemark2: [Number(data.Remark2)] || [0],
          billRemark3: [Number(data.Remark3)] || [0],
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  GetTransBillData: async (ReferenceNumber) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetTransBillData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  SetProductTempData: async (data, ReferenceNumber) => {
    // debugger;
    // var ProductDataList = [];
    // for (var i = 0; i < data?.length; i++) {
    //   // Check if itemId_d2 (data[i].d2_Id) is null or blank
    //   if (data[i].d2_Id == null || data[i].d2_Id === "" || data[i].d2_Id === undefined|| data[i].d2_Id === 0) {
    //     continue; // Skip this iteration if d2_Id is null or blank
    //   }

    //   var obj = {
    //     rowIndex: Number(data[i].rowIndex) || 0,
    //     itemCode: Number(data[i].defaultBarcode) || "",
    //     itemId_d2: Number(data[i].d2_Id) || 0,
    //     itemName: data[i].productName || "",
    //     itmGrpId_d2: Number(data[i].parentGrp) || 0,
    //     itmGrpName: data[i].parentGrpName || "",
    //     godownId: Number(data[i].godownId) || 0,
    //     godownName: data[i].godownName || "",
    //     masterDescription: data[i].masterDescription || "",
    //     itemDescription: data[i].shortDescription || "",
    //     itemAttributes: data[i].itemAttributes || [],
    //     batchNo: Number(data[i].BatchNo) || "",
    //     mgfDate: data[i].MgfDt || new Date().toISOString(),
    //     expDate: data[i].ExpDt || new Date().toISOString(),
    //     unit: Number(data[i].productUnit) || 0,
    //     qty: Number(data[i].Qty) || 0,
    //     freeQty: Number(data[i].FreeQty) || 0,
    //     price: Number(data[i].itemPrice) || 0,
    //     discountPer1: Number(data[i].DiscPer) || 0,
    //     discountAmt1: Number(data[i].DiscAmt) || 0,
    //     discountPer2: Number(data[i].DoubleDis) || 0,
    //     discountAmt2: Number(data[i].DiscAmt) || 0,
    //     salePrice: Number(data[i].salePrice) || 0,
    //     mrpPrice: Number(data[i].mrpPrice) || 0,
    //     purPageSaleProfPer: Number(data[i].purPageSaleProfPer) || 0,
    //     purPageSalePricePer: Number(data[i].purPageSalePricePer) || 0,
    //     purPageMrpPrice: Number(data[i].purPageMrpPrice) || 0,
    //     eye: Number(data[i].lens_Eye) || "",
    //     dia: Number(data[i].lens_Dia) || "",
    //     axis: Number(data[i].lens_AXIS) || 0,
    //     sph: Number(data[i].lens_SPH) || 0,
    //     cyl: Number(data[i].lens_CYL) || 0,
    //     add: Number(data[i].lens_Add) || 0,
    //     extraFields: data[i].extraFields || [],
    //   };
    //   ProductDataList.push(obj);
    // }

    // console.log(Id);


    var ProductDataList = [];
    var obj = {
      rowIndex: Number(data[0].rowIndex) || 0,
      itemCode: String(data[0].defaultBarcode) || "",
      itemId_d2: Number(data[0].d2_Id) || 0,
      itemName: data[0].productName || "",
      itmGrpId_d2: Number(data[0].parentGrp) || 0,
      itmGrpName: data[0].parentGrpName || "",
      godownId: Number(data[0].godownId) || 0,
      godownName: data[0].godownName || "",
      masterDescription: data[0].masterDescription || "",
      itemDescription: data[0].shortDescription || "",
      itemAttributes: data[0].itemAttributes || [],
      batchNo: Number(data[0].BatchNo) || "",
      mgfDate: data[0].MgfDt || new Date().toISOString(),
      expDate: data[0].ExpDt || new Date().toISOString(),
      unit: Number(data[0].productUnit) || 0,
      qty: Number(data[0].Qty) || 0,
      freeQty: Number(data[0].FreeQty) || 0,
      price: Number(data[0].itemPrice) || 0,
      discountPer1: Number(data[0].DiscPer) || 0,
      discountAmt1: Number(data[0].DiscAmt) || 0,
      discountPer2: Number(data[0].DoubleDis) || 0,
      discountAmt2: Number(data[0].disc2Amt) || 0,
      salePrice: Number(data[0].salePrice) || 0,
      mrpPrice: Number(data[0].mrpPrice) || 0,
      purPageSaleProfPer: Number(data[0].purPageSaleProfPer) || 0,
      purPageSalePricePer: Number(data[0].purPageSalePricePer) || 0,
      purPageMrpPrice: Number(data[0].purPageMrpPrice) || 0,
      eye: Number(data[0].lens_Eye) || "",
      dia: Number(data[0].lens_Dia) || "",
      axis: Number(data[0].lens_AXIS) || 0,
      sph: Number(data[0].lens_SPH) || 0,
      cyl: Number(data[0].lens_CYL) || 0,
      add: Number(data[0].lens_Add) || 0,
      extraFields: data[0].extraFields || [],
    };
    ProductDataList.push(obj);
    // debugger
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/SetProductTempData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      if (ProductDataList.length !== 0) {
        const response = await axios.post(
          apiUrl,
          {
            ProductDataList,
          },
          {
            withCredentials: true,
            credentials: "include",
          }
        );
        // debugger;
        console.log("Response:", response.data);
        return response.data;
      }
      else {
        return "no item";
      }
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  GetProductTempData: async (ReferenceNumber) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetProductTempData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // // debugger;
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  SetSundryTempData: async (data, ReferenceNumber) => {
    var SundryDataList = [];
    for (var i = 0; i < data?.length; i++) {
      var obj = {
        rowIndex: Number(data[i].rowIndex) || 0,
        sundryId_d2: Number(data[i].id) || 0,
        adjustmentInItem: data[i].adjustmentInItem || true,
        sundryPer: Number(data[i].Percent) || 0,
        sundryAmt: Number(data[i].Amount) || 0,
      };

      SundryDataList.push(obj);
    }
    // console.log(Id);
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/SetSundryTempData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {
          SundryDataList,
        },
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  GetSundryTempData: async (ReferenceNumber) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetSundryTempData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  SetAccountTempData: async (data, ReferenceNumber) => {
    // debugger
    var AccountTempData = [];
    for (var i = 0; i < data?.length; i++) {
      var obj = {
        rowIndex: i + 1 || 0,
        accountId_d2: Number(data[i].accountId) || 0,
        amt: Number(data[i].PayAmt) || 0,
        dt: data[i].dt || "2024-11-05T07:44:13.047Z",
      };

      AccountTempData.push(obj);
    }
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/SetAccountTempData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(apiUrl, { AccountTempData }, {
        withCredentials: true,
        credentials: "include",
      });
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  GetAccountTempData: async (ReferenceNumber) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetAccountTempData?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  SaveTransactions: async (ReferenceNumber) => {
    // debugger;
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/SaveTransactions?ReferenceNumber=${ReferenceNumber}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },


  GetTransactionList: async (TransType) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetTransactionList?TransType=${TransType}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // debugger;
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

  GetTransactionById: async (Trans1Id, TransType) => {
    try {
      const apiUrl = `${WebApiUrl}/api/Transaction/GetTransactionById?Trans1Id=${Trans1Id}&TransType=${TransType}`;
      console.log(apiUrl);
      const response = await axios.post(
        apiUrl,
        {},
        {
          withCredentials: true,
          credentials: "include",
        }
      );
      // // debugger;
      console.log("Response:", response.data.result);
      return response.data.result;
    } catch (error) {
      console.error("Error:", error.message);

      if (error.response && error.response.status === 401) {
        alert("Failed");
      } else {
        alert("An error occurred. Please try again.");
      }
    }
  },

};

export default Transaction;
