import React, { useContext, useEffect, useState } from "react";
import styletype from "../../Assets/Icons/glasses (1).png";
import styletype1 from "../../Assets/Icons/glasses.png";
import styletype2 from "../../Assets/Icons/hexa.png";
import styletype3 from "../../Assets/Icons/oval-shape-reading-eyeglasses.png";
import styletype4 from "../../Assets/Icons/Square.webp";
import styletype5 from "../../Assets/Icons/Wayfarer.webp";
import styletype6 from "../../Assets/Icons/Rectangle.webp";
import styletype7 from "../../Assets/Icons/sun-glasses.png";
import styletype8 from "../../Assets/Icons/Geometric.webp";
import styletype9 from "../../Assets/Icons/Clubmaster.webp";
import styletype10 from "../../Assets/Icons/Sports.webp";
import { useSpring, animated } from "react-spring";
import MultiRangeSlider from "../../Pages/ProductSearchPage/MultiRangeSlider";
import ItemMasterApi from "../../../API/API_SERVISES/ItemMaster";
import MyContext from "../../MyContext";
import { useLocation } from 'react-router-dom';


const MobileFilterPage = (props) => {

  const { productSearch, setProductSearch, searchName, setSearchName, changeProductGrid, changegrid, setChangegrid, setClose, productlist, setProductList, attributeList, setAttributeList, imageBaseUrl, currentUser, shopBanner, companyData, } = useContext(MyContext)


  const [selectedOption, setSelectedOption] = useState("prize-range");
  const [price, setPrice] = useState(false);
  const [maxSalePrice, setMaxSalePrice] = useState(0);
  const [shopFor, setShopFor] = useState(false);
  const [color, setColor] = useState(false);
  const [style, setStyle] = useState(false);
  const [shape, setShape] = useState(true);
  const [size, setSize] = useState(false);
  const [material, setMaterial] = useState(false);
  const [minVal, setMinVal] = useState(0);
  const [maxVal, setMaxVal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [activeAttrId, setActiveAttrId] = useState([]);
  const [childAttrId, setChildAttrId] = useState(null);
  const [filteredata2, setFilteredData2] = useState([]);
  const [filterProducts, setFilterProducts] = useState(false);


  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

  const location = useLocation();
  const query = useQuery();
  const productId = query.get('productId');
  const searchValueData = location?.state?.searchValue || "";


  useEffect(() => {
    if (productId) {
      fetchData2(productId);
    }
  }, [productId]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    setMaxVal(maxSalePrice);
  }, [maxSalePrice]);


  //******************* Filter ************************* */

  const fetchDataFilter = async () => {
    setLoading(true);
    if (maxVal !== 0) {
      const filteredata = [
        {
          fieldName: "salePrice",
          operatorName: "lessthan",
          compareValue: maxVal
        },
        {
          fieldName: "salePrice",
          operatorName: "greaterthan",
          compareValue: minVal
        },

      ]
      try {
        const categoryName = await ItemMasterApi.GetItemMasterFilter(filteredata);
        console.log(categoryName);
        setProductList(categoryName)
        // setMaxSalePrice(0)
        setLoading(false);
        console.log("Search", searchName)
      } catch (error) {
        setLoading(false);
        console.error("Error Fetching Data:", error);
      }
      setLoading(false);
    }
  };
  useEffect(() => {
    if (productId != null) {

      fetchDataFilter()
    }
  }, [minVal, maxVal, searchName]);



  const fetchData2 = async () => {
    setLoading(true)
    try {
      const val = await ItemMasterApi.GetEcomItemFilterFromCategory(productId);
      console.log("This is Ecom", val)
      setAttributeList(val);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)

    }
  };

  useEffect(() => {

    const maxfunc = async () => {
      // debugger
      if (productlist.length > 0) {
        const maxPrice = await productlist.reduce(
          (max, product) => Math.max(max, product.salePrice),
          -Infinity
        );
        setMaxSalePrice(maxPrice + 50);
      }
    }
    maxfunc()
  }, [productId]);

  const [visibleItems, setVisibleItems] = useState(4);
  const defaultVisibleCount = 10;

  // const toggleOption = (attributeName) => {
  //   setVisibleItems(prevState => ({
  //     ...prevState,
  //     [attributeName]: prevState[attributeName] === defaultVisibleCount ? null : defaultVisibleCount
  //   }));
  // };


  const toggleOption = (attributeName) => {
    setVisibleItems(prevState => {
      // Hide all attributes except the clicked one
      const newVisibleItems = { ...prevState };
      Object.keys(newVisibleItems).forEach(attr => {
        if (attr !== attributeName) {
          newVisibleItems[attr] = null; // Hide other lists
        }
      });
      
      // Toggle visibility for the clicked attribute
      newVisibleItems[attributeName] = newVisibleItems[attributeName] === defaultVisibleCount ? null : defaultVisibleCount;
      return newVisibleItems;
    });
  };

  const handleMoreClick = (attributeName) => {
    setVisibleItems(prevState => ({
      ...prevState,
      [attributeName]: prevState[attributeName] + defaultVisibleCount
    }));
  };

  const handleHideClick = (attributeName) => {
    setVisibleItems(prevState => ({
      ...prevState,
      [attributeName]: defaultVisibleCount
    }));
  };

  const handleParentClick = (parentId) => {
    setParentId(parentId);
    console.log("Parent ID:", parentId);

  };


  const handleChildClick = async (attrId) => {
    setLoading(true);
    // debugger
    setActiveAttrId((prevActiveAttrIds) => {
      if (prevActiveAttrIds.includes(attrId)) {
        return prevActiveAttrIds.filter(id => id !== attrId);
      } else {
        return [...prevActiveAttrIds, attrId];
      }
    });

    setChildAttrId(attrId);
    console.log("Child Attr ID:", attrId);

    let updatedValues;

    if (activeAttrId.includes(attrId)) {
      // If !active, remove the attribute
      updatedValues = activeAttrId.filter(id => id !== attrId).map(id => ({
        parentId,
        attrId: id,
      }));
    } else {
      // If active, add the attribute
      updatedValues = [
        ...activeAttrId.map(id => ({
          parentId,
          attrId: id,
        })),
        {
          parentId,
          attrId,
        }
      ];
    }

    const filteredata1 = [
      {
        fieldName: "string",
        operatorName: "string",
        compareValue: "string"
      }
    ];

    try {
      const filteredata = {
        filter: filteredata1,
        order: [
          {
            propertyName: "string",
            ascending: true
          }
        ],
        attribute: updatedValues,
        pageNumber: 0,
        pageSize: 50000
      };

      console.log(filteredata);

      const response = await ItemMasterApi.GetItemMasterFilterAttribute(filteredata);
      if (response) {
        console.log(response);
        setProductList(response);
      }
    } catch (error) {
      console.error('Error fetching filtered data:', error);
    } finally {
      setLoading(false);
    }
  };


  // ******************Filter product on the click of attribute***************************
  const handleCheckboxChange12 = async (attrId, attributeId, checked) => {
    setLoading(true);

    let updatedValues;

    if (checked) {
      // If checked, add the filter to filteredData2
      setFilteredData2(prevFilteredData2 => [
        ...prevFilteredData2,
        {
          parentId: attributeId,
          attrId: attrId,
        }
      ]);
      updatedValues = [
        ...filteredata2,
        {
          "parentId": attributeId,
          "attrId": attrId,
        }
      ];
    } else {
      // If unchecked, remove the filter from filteredData2
      setFilteredData2(prevFilteredData2 => prevFilteredData2.filter(filter => !(filter.parentId === attributeId && filter.attrId === attrId)));

      // Remove the filter from updatedValues
      updatedValues = filteredata2.filter(filter => !(filter.parentId === attributeId && filter.attrId === attrId));
    }
    const filteredata1 = [
      {
        fieldName: "string",
        operatorName: "string",
        compareValue: "string"
      }
    ];

    try {
      const filteredata = {
        filter: filteredata1,
        order: [
          {
            propertyName: "string",
            ascending: true
          }
        ],
        attribute: updatedValues,
        pageNumber: 0,
        pageSize: 50000
      };

      console.log(filteredata);

      const categoryName = await ItemMasterApi.GetItemMasterFilterAttribute(filteredata);
      setFilterProducts(categoryName);
      console.log(categoryName);
      setProductList(categoryName);
    } catch (error) {
      console.error("Error Fetching Data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }

  };


  const fadeAnimation = useSpring({
    opacity: price ? 1 : 0,
    height: price ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation1 = useSpring({
    opacity: shopFor ? 1 : 0,
    height: shopFor ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation2 = useSpring({
    opacity: color ? 1 : 0,
    height: color ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation3 = useSpring({
    opacity: style ? 1 : 0,
    height: style ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation5 = useSpring({
    opacity: size ? 1 : 0,
    height: size ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation6 = useSpring({
    opacity: material ? 1 : 0,
    height: material ? 80 : 0,
    config: { duration: 200 },
  });

  const renderSelectedOption = () => {
    switch (selectedOption) {
      case "prize-range":
        return (
          <div className="filter-options">
            {" "}
            {/* <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" />
              <p style={{ margin: "0" }}>
                0 to 500 <i className="fa-solid fa-indian-rupee-sign"></i>
              </p>
            </div> */}
            {/* {price && ( */}
            <animated.div style={fadeAnimation} className="filter-line">
              {" "}
              <MultiRangeSlider
                min={0}
                max={maxSalePrice}
                handleCheckboxChange
                minVal={minVal}
                setMinVal={setMinVal}
                maxVal={maxVal}
                setMaxVal={setMaxVal}
                onChange={({ min, max }) => console.log(`min = ${min}, max = ${max}`)}

              />
            </animated.div>

            {Array.isArray(attributeList) && attributeList.map(attribute => (
              <React.Fragment key={attribute.attributeName}>
                {/* <div className="filter-heading" onClick={() => { toggleOption(attribute.attributeName); handleParentClick(attribute.attributeId); }}>
                  {attribute.attributeName}
                  {visibleItems[attribute.attributeName] ? (
                    <i className="fa-solid fa-caret-up option-icon1"></i>
                  ) : (
                    <i className="fa-solid fa-sort-down option-icon"></i>
                  )}
                </div> */}
                {/* <div className="filter-options"> */}
                <div className="filter-scroll-theme2">
                  {visibleItems[attribute.attributeName] && (
                    <React.Fragment>
                      {/* Items with images */}
                      <div className="filter-options">
                        <div className="filter-line">
                          <animated.div className="filter-options">
                            <div className="style-filter-box-wrap">
                              {attribute.attributeChildren
                                .filter(child => child.imageId)
                                .slice(0, visibleItems[attribute.attributeName])
                                .map(child => (

                                  <div key={child.attrId} className={`style-filter-1 ${activeAttrId.includes(child.attrId) ? 'style-filter-1-active' : 'style-filter-1'}`}
                                    onClick={() => handleChildClick(child.attrId)}>
                                    <div
                                      className="filter-type-image"
                                      style={{
                                        backgroundImage: child.imageId ? `url(${imageBaseUrl}${child.imageId})` : 'none',
                                        height: '40px',
                                        backgroundSize: '85%',
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",

                                      }}
                                    />
                                    <p style={{ marginTop: "1px" }}>{child.attrName}</p>
                                  </div>
                                ))}
                            </div>
                          </animated.div>
                        </div>
                      </div>
                    
                      {/* Items without images */}
                      {attribute.attributeChildren
                        .filter(child => !child.imageId)
                        .slice(0, visibleItems[attribute.attributeName])
                        .map(child => (
                          <div key={child.attrId} className="filter-options">
                            <div className="filter-line">
                              <input
                                type="checkbox"
                                className="ui-checkbox"
                                value={`${child.attrId},${attribute.attributeId}`}
                                checked={filteredata2[`${child.attrId},${attribute.attributeId}`]}
                                onChange={() => handleCheckboxChange12(
                                  child.attrId,
                                  attribute.attributeId,
                                  !filteredata2.some(filter => filter.parentId === attribute.attributeId && filter.attrId === child.attrId)
                                )}
                              />
                              {attribute.attributeName.toLowerCase() === 'color' && (
                                <div style={{ background: child.attrName }} className="filterclrset"></div>
                              )}
                              <p className="" style={{ margin: "0" }}>{child.attrName}</p>
                            </div>
                          </div>
                        ))}

                      {/* "More" and "Hide" buttons */}
                      {attribute.attributeChildren.length > visibleItems[attribute.attributeName] && (
                        <p
                          style={{ textAlign: "center", color: "$Theme-color", cursor: "pointer" }}
                          onClick={() => handleMoreClick(attribute.attributeName)}
                        >
                          {attribute.attributeChildren.length - visibleItems[attribute.attributeName]} More
                        </p>
                      )}
                      {visibleItems[attribute.attributeName] > defaultVisibleCount && (
                        <p
                          style={{ textAlign: "center", color: "$Theme-color", cursor: "pointer" }}
                          onClick={() => handleHideClick(attribute.attributeName)}
                        >
                          Hide
                        </p>
                      )}
                    </React.Fragment>


                  )}
                </div>
                {/* </div> */}
              </React.Fragment>
            ))}


            {/* )} */}
            {/* <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>
                500 to 1000 <i className="fa-solid fa-indian-rupee-sign"></i>
              </p>
            </div> */}
            {/* <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>
                1000 to 1500 <i className="fa-solid fa-indian-rupee-sign"></i>
              </p>
            </div> */}
            {/* <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>
                1500 to 3000 <i className="fa-solid fa-indian-rupee-sign"></i>
              </p>
            </div> */}
          </div>
        );
      case "Shop-For":
        return (
          <div className="filter-options">
            {" "}
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" />
              <p style={{ margin: "0" }}>filter-name</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>filter-name</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>filter-name</p>
            </div>
          </div>
        );
      case "Colour":
        return (
          <div className="filter-options">
            {" "}
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" />
              <p style={{ margin: "0" }}>Black</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>Red</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>Blue</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>White</p>
            </div>
          </div>
        );
      case "Style":
        return (
          <div className="style-filter-box">
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype} alt="" />{" "}
              <p>ROUND</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype1} alt="" />{" "}
              <p>CATEYE</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype2} alt="" />{" "}
              <p>HEXAGONAL</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype3} alt="" />{" "}
              <p>OVAL</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype4}
                alt=""
              />{" "}
              <p>SQUARE</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype5}
                alt=""
              />{" "}
              <p>WAYFARER</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype6}
                alt=""
              />{" "}
              <p>RECTANGLE</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype7} alt="" />{" "}
              <p>AVIATOR</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype8}
                alt=""
              />{" "}
              <p>GEOMATRIC</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype9}
                alt=""
              />{" "}
              <p>CLUBMASTER</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype10}
                alt=""
              />{" "}
              <p>SPORTS</p>
            </div>
          </div>
        );
      case "Shape":
        return (
          <div className="style-filter-box">
            {" "}
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype} alt="" />{" "}
              <p>ROUND</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype1} alt="" />{" "}
              <p>CATEYE</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype2} alt="" />{" "}
              <p>HEXAGONAL</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype3} alt="" />{" "}
              <p>OVAL</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype4}
                alt=""
              />{" "}
              <p>SQUARE</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype5}
                alt=""
              />{" "}
              <p>WAYFARER</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype6}
                alt=""
              />{" "}
              <p>RECTANGLE</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img className="filter-type-image" src={styletype7} alt="" />{" "}
              <p>AVIATOR</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype8}
                alt=""
              />{" "}
              <p>GEOMATRIC</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype9}
                alt=""
              />{" "}
              <p>CLUBMASTER</p>
            </div>
            <div className="style-filter-1">
              {" "}
              <img
                className="filter-type-image-1"
                src={styletype10}
                alt=""
              />{" "}
              <p>SPORTS</p>
            </div>
          </div>
        );
      case "Size":
        return (
          <div className="filter-options">
            {" "}
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" />
              <p style={{ margin: "0" }}>EXTRA NARROW</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>EXTRA WIDE</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>MEDIUM</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>NARROW</p>
            </div>  <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>WIDE</p>
            </div>
          </div>
        );
      case "Material":
        return (
          <div className="filter-options">
            {" "}
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" />
              <p style={{ margin: "0" }}>filter-name</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>filter-name</p>
            </div>
            <div className="filter-line">
              <input type="checkbox" className="ui-checkbox" name="" id="" />
              <p style={{ margin: "0" }}>filter-name</p>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="mobile-filter-page">
      <i
        onClick={props.close}
        className="filter-back-button fa-solid fa-arrow-left-long"
      ></i>
      <div className="mobile-filter-page-top">
        Filter <i style={{ marginLeft: "15px" }} className="fa-solid fa-filter"></i>
      </div>
      <div className="mobile-filter-page-main">
        <div className="mobile-filter-page-left">
          <div
            className={`MobileFilter-options ${selectedOption === "prize-range" ? "mobile-filter-active" : ""
              }`}
            onClick={() => handleOptionClick("prize-range")}
          >
            {" "}
            Price-Range
          </div>
          {/* <div
            className={`MobileFilter-options ${
              selectedOption === "Shop-For" ? "mobile-filter-active" : ""
            }`}
            onClick={() => handleOptionClick("Shop-For")}
          >
            Shop For
          </div> */}
          {Array.isArray(attributeList) && attributeList.map(attribute => (
            <React.Fragment key={attribute.attributeName}>
          <div className={`MobileFilter-options ${selectedOption === "Colour" ? "mobile-filter-active" : ""
              }`} onClick={() => { toggleOption(attribute.attributeName); handleParentClick(attribute.attributeId); }}>
            {attribute.attributeName}
            {/* {visibleItems[attribute.attributeName] } */}
            {visibleItems[attribute.attributeName] && visibleItems[attribute.attributeName] > 0 && (
              <span>({visibleItems[attribute.attributeName]})</span>
            )}
          </div>
          </React.Fragment>
           ))}

          {/* <div
            className={`MobileFilter-options ${selectedOption === "Colour" ? "mobile-filter-active" : ""
              }`}
            onClick={() => handleOptionClick("Colour")}
          >
            Colour
          </div> */}
          {/* <div
            className={`MobileFilter-options ${selectedOption === "Style" ? "mobile-filter-active" : ""
              }`}
            onClick={() => handleOptionClick("Style")}
          >
            Style
          </div> */}
          {/* <div
            className={`MobileFilter-options ${selectedOption === "Shape" ? "mobile-filter-active" : ""
              }`}
            onClick={() => handleOptionClick("Shape")}
          >
            Shape
          </div> */}
          {/* <div
            className={`MobileFilter-options ${selectedOption === "Size" ? "mobile-filter-active" : ""
              }`}
            onClick={() => handleOptionClick("Size")}
          >
            Size
          </div> */}
          {/* <div
            className={`MobileFilter-options ${selectedOption === "Material" ? "mobile-filter-active" : ""
              }`}
            onClick={() => handleOptionClick("Material")}
          >
            Material
          </div> */}
        </div>
        <div className="mobile-filter-page-right">{renderSelectedOption()}</div>
      </div>
      <div className="Button-box-filter">
        <button onClick={props.close} className="Button-1-filter">
          Cancel
        </button>
        <button className="Button-2-filter">Add Filter</button>
      </div>
    </div>
  );
};

export default MobileFilterPage;
