import React, { useEffect, useState, useContext } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactImageMagnify from 'react-image-magnify';
import MyContext from "../../../MyApp/MyContext";
// import './ProductSwap2.css'; // Import your custom styles if needed

const ProductSwap4 = (props) => {

  const { items } = props

  const { imageBaseUrl } = useContext(MyContext);

  const [enlargedImagePosition, setEnlargedImagePosition] = useState('beside');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setEnlargedImagePosition('over');
      } else {
        setEnlargedImagePosition('beside');
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // var settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   // autoplay: true,
  //   // autoplaySpeed: 3000,
  // };

  const imageurl = `${imageBaseUrl}${items.images[3]}`;
  // const imageurl2 = 'https://www.specsplus.in/shop/images/product/SNOOPY_160229_699_2.jpg';

  return (
    <>
      {/* <Slider className="product-swap-2" {...settings}> */}
      <div className="productswap-deteil-2" style={{ zIndex: "3" }}>
        <ReactImageMagnify

          {...{
            smallImage: {
              isFluidWidth: true,
              src: imageurl,
            },
            largeImage: {
              src: imageurl,
              width: 1500,
              height: 1500,
            },
            style: {
              backgroundColor: 'white',
            },
            enlargedImageContainerDimensions: {
              width: "100%",
              height: '100%',
              backgroundColor: "black"
            }
            ,
            enlargedImagePosition: enlargedImagePosition,

          }}
        />
      </div>


    </>
  );
};

export default ProductSwap4;
