import React, { useState } from "react";
import dlt from "../../Assets/Icons/trash.png";
// import MyContext from "./MyContext";

const MyPrescription = () => {

    const data = [
        { id: 1, eye: 'SPHERICAL', rightEye: '-3.50', leftEye: '-4.00' },
        { id: 2, eye: 'CYLINDRICAL', rightEye: '-0.75', leftEye: '-1.25' },
        { id: 3, eye: 'AXIS', rightEye: '150', leftEye: '130' },
        { id: 4, eye: 'AP', rightEye: '-', leftEye: '-' },
      ];

    return (
        <div>
            <div className="Prescription-heading">
                <div className="saved-prescription">My Saved Prescription</div>
                <div className="flex-prescripton">
                    <div className="left-prescription-width">
                        <div className="flex-power-type">
                            <p className="prescription-name-user">NAME : </p>
                            <p className="prescription-name-user">LENS2CART USER</p>
                        </div>
                        <div className="flex-power-type">
                            <p className="prescription-name-user">POWER TYPE : </p>
                            <p className="prescription-name-user">CONTACT LENS</p>
                        </div>
                        <div className="date-create-type">
                            <p>Created Wed Jun 19 2024</p>
                        </div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead className="header-prescription-eye">
                                <tr>
                                    <th className="header-left-right-eye">EYE</th>
                                    <th className="header-left-right-eye">RIGHT EYE</th>
                                    <th className="header-left-right-eye">LEFT EYE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(row => (
                                    <tr key={row.id}>
                                        <td className="table-left-right-data">{row.eye}</td>
                                        <td className="table-prescription-data">{row.rightEye}</td>
                                        <td className="table-prescription-data">{row.leftEye}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="right-prescription-width">
                        <div className="flex-power-type">
                            <p className="prescription-name-user">NAME : </p>
                            <p className="prescription-name-user">LENS2CART USER</p>
                        </div>
                        <div className="flex-power-type">
                            <p className="prescription-name-user">POWER TYPE : </p>
                            <p className="prescription-name-user">CONTACT LENS</p>
                        </div>
                        <div className="date-create-type">
                            <p>Created Wed Jun 19 2024</p>
                        </div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead className="header-prescription-eye">
                                <tr>
                                    <th className="header-left-right-eye">EYE</th>
                                    <th className="header-left-right-eye">RIGHT EYE</th>
                                    <th className="header-left-right-eye">LEFT EYE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(row => (
                                    <tr key={row.id}>
                                        <td className="table-left-right-data">{row.eye}</td>
                                        <td className="table-prescription-data">{row.rightEye}</td>
                                        <td className="table-prescription-data">{row.leftEye}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default MyPrescription;
