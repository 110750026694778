import React, { useEffect, useState } from "react";
import OrderApi from "../../../API/API_SERVISES/Order";

const ModelPrescriptionManually = ({ onSelect, onContinue, isSubmitted }) => {

    const [showTable, setShowTable] = useState(false);
    const data = [
        { id: 1, eye: 'SPHERICAL', rightEye: '-3.50', leftEye: '-4.00' },
        // { id: 2, eye: 'CYLINDRICAL', rightEye: '-3.50', leftEye: '-4.00' },

    ];

    const [negativeOptionsSph] = useState([
        -13.00, -12.75, -12.50, -12.25, -12.00, -11.75, -11.50, -11.25, -11.00,
        -10.75, -10.50, -10.25, -10.00, -9.75, -9.50, -9.25, -9.00, -8.75, -8.50,
        -8.25, -8.00, -7.75, -7.50, -7.25, -7.00, -6.75, -6.50, -6.25, -6.00,
        -5.75, -5.50, -5.25, -5.00, -4.75, -4.50, -4.25, -4.00, -3.75, -3.50,
        -3.25, -3.00, -2.75, -2.50, -2.25, -2.00, -1.75, -1.50, -1.25, -1.00,
        -0.75, -0.50, -0.25
    ]);

    const [positiveOptionsSph] = useState([
        0.25, 0.50, 0.75, 1.00, 1.25, 1.50, 1.75, 2.00, 2.25, 2.50, 2.75, 3.00,
        3.25, 3.50, 3.75, 4.00, 4.25, 4.50, 4.75, 5.00, 5.25, 5.50, 5.75, 6.00,
        6.25, 6.50, 6.75, 7.00, 7.25, 7.50, 7.75, 8.00
    ]);

    const [negativeOptionsCyl] = useState([
        -6.00, -5.75, -5.50, -5.25, -5.00, -4.75, -4.50, -4.25, -4.00, -3.75,
        -3.50, -3.25, -3.00, -2.75, -2.50, -2.25, -2.00, -1.75, -1.50, -1.25,
        -1.00, -0.75, -0.50, -0.25
    ]);

    const [positiveOptionsCyl] = useState([
        0.25, 0.50, 0.75, 1.00, 1.25, 1.50,
        1.75, 2.00, 2.25, 2.50, 2.75, 3.00, 3.25, 3.50, 3.75, 4.00,
        4.25, 4.50, 4.75, 5.00, 5.25, 5.50, 5.75, 6.00
    ]);

    const handleSubmitClick = () => {
        setShowTable(true);
    };

    const handleBackClick = () => {
        setShowTable(false);
    };

    // function generateRanges(start, end, interval) {
    //     const step = parseFloat(interval);
    //     const startT = parseFloat(start);
    //     const endT = parseFloat(end);

    //     const result = [];

    //     for (let value = startT; value <= endT; value += step) {

    //         const roundedValue = Math.round(value * 100) / 100;

    //         result.push(roundedValue);
    //     }
    //     return result;
    // }


    // useEffect(() => {
    //     const fetchData = async () => {
    //         const lens = await OrderApi.getLensMasterSettAndFeatures(5419); //d2ItemId
    //         //*********** Sph ****************** */
    //         const sphSplit = lens?.rE_SPH?.split(",")
    //         const DataSphRight = generateRanges((sphSplit[0]), (sphSplit[1]), lens.sph_Step);
    //         const negativeValues = DataSphRight.filter(num => num < 0).sort((a, b) => a - b);
    //         const positiveValues = DataSphRight.filter(num => num > 0).sort((a, b) => a - b);
    //         setNegativeOptionsRight(negativeValues);
    //         setPositiveOptionsRight(positiveValues);

    //         const sphSplitLeft = lens?.lE_SPH?.split(",")
    //         const DataSphLeft = generateRanges((sphSplitLeft[0]), (sphSplitLeft[1]), lens.sph_Step);
    //         const negativeValuesLeft = DataSphLeft.filter(num => num < 0).sort((a, b) => a - b);
    //         const positiveValuesLeft = DataSphLeft.filter(num => num > 0).sort((a, b) => a - b);
    //         setNegativeOptionsLeft(negativeValuesLeft);
    //         setPositiveOptionsLeft(positiveValuesLeft);
    //     };
    //     fetchData();
    // }, []);

    /*********************************************************** */

    const [selectedValueRight, setSelectedValueRight] = useState('Select');
    const [isOpenRight, setIsOpenRight] = useState(false);

    const toggleDropdownRight = () => {
        setIsOpenRight(!isOpenRight);
        setIsOpenLeftCyl(false)
        setIsOpenRightCyl(false)
        setIsOpenLeft(false)
        setIsOpenRightAxis(false)
        setIsOpenLeftAxis(false)
    };

    const handleSelectRight = (value) => {
        setSelectedValueRight(value);
        if (typeof onSelect === 'function') {
            onSelect(value);
        }
        setIsOpenRight(false);
    };

    /************************************************************* */
    const [selectedValueLeft, setSelectedValueLeft] = useState('Select');
    const [isOpenLeft, setIsOpenLeft] = useState(false);

    const toggleDropdownLeft = () => {
        setIsOpenLeft(!isOpenLeft);
        setIsOpenLeftCyl(false)
        setIsOpenRightCyl(false)
        setIsOpenRight(false)
        setIsOpenRightAxis(false)
        setIsOpenLeftAxis(false)
    };

    const handleSelectLeft = (value) => {
        setSelectedValueLeft(value);
        if (typeof onSelect === 'function') {
            onSelect(value);
        }
        setIsOpenLeft(false);
    };

    // ***************** CYLINDRICAL ******************

    const [isShowCylindrical, setIsShowCylindrical] = useState(false);

    const handleCheckBox = () => {
        setIsShowCylindrical(!isShowCylindrical)
    }

    const [selectedValueRightCyl, setSelectedValueRightCyl] = useState('Select');
    const [isOpenRightCyl, setIsOpenRightCyl] = useState(false);

    const toggleDropdownRightCyl = () => {
        setIsOpenRightCyl(!isOpenRightCyl);
        setIsOpenLeftCyl(false)
        setIsOpenLeft(false)
        setIsOpenRight(false)
        setIsOpenRightAxis(false)
        setIsOpenLeftAxis(false)
    };

    const handleSelectRightCyl = (value) => {
        setSelectedValueRightCyl(value);
        if (typeof onSelect === 'function') {
            onSelect(value);
        }
        setIsOpenRightCyl(false);
    };

    /************************************************************* */
    const [selectedValueLeftCyl, setSelectedValueLeftCyl] = useState('Select');
    const [isOpenLeftCyl, setIsOpenLeftCyl] = useState(false);

    const toggleDropdownLeftCyl = () => {
        setIsOpenLeftCyl(!isOpenLeftCyl);
        setIsOpenRightCyl(false)
        setIsOpenLeft(false)
        setIsOpenRight(false)
        setIsOpenRightAxis(false)
        setIsOpenLeftAxis(false)
    };

    const handleSelectLeftCyl = (value) => {
        setSelectedValueLeftCyl(value);
        if (typeof onSelect === 'function') {
            onSelect(value);
        }
        setIsOpenLeftCyl(false);
    };

    // **************** AXIS ************************
    const [isShowAxis, setIsShowAxis] = useState(false);

    const handleCheckBoxAxis = () => {
        setIsShowAxis(!isShowAxis)
    }

    const [selectedValueRightAxis, setSelectedValueRightAxis] = useState('Select');
    const [isOpenRightAxis, setIsOpenRightAxis] = useState(false);

    const toggleDropdownRightAxis = () => {
        setIsOpenRightAxis(!isOpenRightAxis);
        setIsOpenRightCyl(false);
        setIsOpenLeftCyl(false)
        setIsOpenLeft(false)
        setIsOpenRight(false)
        setIsOpenLeftAxis(false)
    };

    const handleSelectRightAxis = (value) => {
        setSelectedValueRightAxis(value);
        if (typeof onSelect === 'function') {
            onSelect(value);
        }
        setIsOpenRightAxis(false);
    };

    /************************************************************* */
    const [selectedValueLeftAxis, setSelectedValueLeftAxis] = useState('Select');
    const [isOpenLeftAxis, setIsOpenLeftAxis] = useState(false);

    const toggleDropdownLeftAxis = () => {
        setIsOpenLeftAxis(!isOpenLeftAxis);
        setIsOpenLeftCyl(false);
        setIsOpenRightCyl(false)
        setIsOpenLeft(false)
        setIsOpenRight(false)
        setIsOpenRightAxis(false)
    };

    const handleSelectLeftAxis = (value) => {
        setSelectedValueLeftAxis(value);
        if (typeof onSelect === 'function') {
            onSelect(value);
        }
        setIsOpenLeftAxis(false);
    };

    return (
        <div>{/*onClick={handleCloseSelectBox}*/}
            <div className="flex-ord-sold mdl-manual-pre">
                <div className="review-box-image order-image-box">
                    <img className="favourite-img fav-image-ord"
                        src="https://www.ashishoptics.com/shop/images/product/ESCAPE_1833_DARK_BLUE_918_1.jpg"
                        alt=""
                    />
                </div>
                <div className="review-box-detail item-details-width-ord">
                    <div className="review-box-detail-line-1">
                        <div className="name-top-item">

                            Lens2cart Hustlr Eyeglasses
                            + Hydrophobic Anti-Glare

                        </div>
                    </div>
                </div>
            </div>

            <div className="learn-read-prescription">
                {!showTable && (
                    <div>
                        <div className="power-details-pres">
                            <div className="add-power-details">Add Power Details</div>
                            <div className="rigt-leran-prescription">LEARN HOW TO READ PRESCRIPTION</div>
                        </div>
                        <table className="table-head-model-top" style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead className="header-prescription-eye">
                                <tr>
                                    <th className="header-left-right-eye">EYE</th>
                                    <th className="header-left-right-eye">RIGHT EYE</th>
                                    <th className="header-left-right-eye">LEFT EYE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* ************************** Spherical ************************* */}
                                {data.map(row => (
                                    <tr key={row.id}>
                                        <td className="table-left-right-data">{row.eye}</td>
                                        <td className="table-prescription-data">
                                            <div className="dd-container-pres">
                                                <div className="custom-dropdown-pres cl-dd">
                                                    <div
                                                        className={`cl-dd dropdown-container-pres ${isOpenRight ? 'show' : ''}`}
                                                        onClick={toggleDropdownRight}
                                                    >
                                                        <div className="dropdown-display-pres clicked cl-dd">
                                                            <span className="selected-value-pres">{selectedValueRight}</span>
                                                            <i aria-hidden="true" className={`fa-solid fa-angle-${isOpenRight ? 'up' : 'down'} cl-dd`} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {isOpenRight && (
                                                <div className="dropdown-list-pres negative positive both" style={{ zIndex: "1" }}>
                                                    <div className="cl-power-list">
                                                        <div className="top-pres-Zero">0.00</div>
                                                        <div className="list-container" style={{ display: 'flex' }}>
                                                            <div className="negative-list" style={{ flex: 1 }}>
                                                                <ul className="list pull-left zero full-width">
                                                                    {negativeOptionsSph.map((option, index) => (
                                                                        <li key={index} onClick={() => handleSelectRight(option)}>
                                                                            <div>{option}</div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                            <div className="positive-list" style={{ flex: 1 }}>
                                                                <ul className="list pull-left zero full-width">
                                                                    {positiveOptionsSph.map((option, index) => (
                                                                        <li key={index} onClick={() => handleSelectRight(option)}>
                                                                            <div>{option}</div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </td>
                                        <td className="table-prescription-data">
                                            <div className="dd-container-pres">
                                                <div className="custom-dropdown-pres cl-dd">
                                                    <div
                                                        className={`cl-dd dropdown-container-pres ${isOpenLeft ? 'show' : ''}`}
                                                        onClick={toggleDropdownLeft}
                                                    >
                                                        <div className="dropdown-display-pres clicked cl-dd">
                                                            <span className="selected-value-pres">{selectedValueLeft}</span>
                                                            <i
                                                                aria-hidden="true"
                                                                className={`fa-solid fa-angle-${isOpenLeft ? 'up' : 'down'} cl-dd`}
                                                            />

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {isOpenLeft && (
                                                <div className="dropdown-list-pres negative positive both" style={{ zIndex: "1" }}>
                                                    <div className="cl-power-list">
                                                        <div className="top-pres-Zero">0.00</div>
                                                        <div className="list-container" style={{ display: 'flex' }}>
                                                            <div className="negative-list" style={{ flex: 1 }}>
                                                                <ul className="list pull-left zero full-width">
                                                                    {negativeOptionsSph.map((option, index) => (
                                                                        <li key={index} onClick={() => handleSelectLeft(option)}>
                                                                            <div>{option}</div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                            <div className="positive-list" style={{ flex: 1 }}>
                                                                <ul className="list pull-left zero full-width">
                                                                    {positiveOptionsSph.map((option, index) => (
                                                                        <li key={index} onClick={() => handleSelectLeft(option)}>
                                                                            <div>{option}</div>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                {/* ************************ Cylindrical ********************** */}
                                {isShowCylindrical && (
                                    <>
                                        <tr>
                                            <td className="table-left-right-data">CYLINDRICAL</td>
                                            <td className="table-prescription-data">
                                                <div className="dd-container-pres">
                                                    <div className="custom-dropdown-pres cl-dd">
                                                        <div
                                                            className={`cl-dd dropdown-container-pres ${isOpenRightCyl ? 'show' : ''}`}
                                                            onClick={toggleDropdownRightCyl}
                                                        >
                                                            <div className="dropdown-display-pres clicked cl-dd">
                                                                <span className="selected-value-pres">{selectedValueRightCyl}</span>
                                                                <i aria-hidden="true" className={`fa-solid fa-angle-${isOpenRightCyl ? 'up' : 'down'} cl-dd`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {isOpenRightCyl && (
                                                    <div className="dropdown-list-pres negative positive both" style={{ zIndex: "1" }}>
                                                        <div className="cl-power-list">
                                                            <div className="top-pres-Zero">0.00</div>
                                                            <div className="list-container" style={{ display: 'flex' }}>
                                                                <div className="negative-list" style={{ flex: 1 }}>
                                                                    <ul className="list pull-left zero full-width">
                                                                        {negativeOptionsCyl.map((option, index) => (
                                                                            <li key={index} onClick={() => handleSelectRightCyl(option)}>
                                                                                <div>{option}</div>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                                <div className="positive-list" style={{ flex: 1 }}>
                                                                    <ul className="list pull-left zero full-width">
                                                                        {positiveOptionsCyl.map((option, index) => (
                                                                            <li key={index} onClick={() => handleSelectRightCyl(option)}>
                                                                                <div>{option}</div>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                            <td className="table-prescription-data">
                                                <div className="dd-container-pres">
                                                    <div className="custom-dropdown-pres cl-dd">
                                                        <div
                                                            className={`cl-dd dropdown-container-pres ${isOpenLeftCyl ? 'show' : ''}`}
                                                            onClick={toggleDropdownLeftCyl}
                                                        >
                                                            <div className="dropdown-display-pres clicked cl-dd">
                                                                <span className="selected-value-pres">{selectedValueLeftCyl}</span>
                                                                <i
                                                                    aria-hidden="true"
                                                                    className={`fa-solid fa-angle-${isOpenLeftCyl ? 'up' : 'down'} cl-dd`}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {isOpenLeftCyl && (
                                                    <div className="dropdown-list-pres negative positive both" style={{ zIndex: "1" }} >
                                                        <div className="cl-power-list">
                                                            <div className="top-pres-Zero">0.00</div>
                                                            <div className="list-container" style={{ display: 'flex' }}>
                                                                <div className="negative-list" style={{ flex: 1 }}>
                                                                    <ul className="list pull-left zero full-width">
                                                                        {negativeOptionsCyl.map((option, index) => (
                                                                            <li key={index} onClick={() => handleSelectLeftCyl(option)}>
                                                                                <div>{option}</div>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                                <div className="positive-list" style={{ flex: 1 }}>
                                                                    <ul className="list pull-left zero full-width">
                                                                        {positiveOptionsCyl.map((option, index) => (
                                                                            <li key={index} onClick={() => handleSelectLeftCyl(option)}>
                                                                                <div>{option}</div>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                        {/* *********************** Axis ********************** */}
                                        <tr>
                                            <td className="table-left-right-data">AXIS</td>
                                            <td className="table-prescription-data">
                                                <div className="dd-container-pres">
                                                    <div className="custom-dropdown-pres cl-dd">
                                                        <div
                                                            className={`cl-dd dropdown-container-pres ${isOpenRightAxis ? 'show' : ''}`}
                                                            onClick={toggleDropdownRightAxis}
                                                        >
                                                            <div className="dropdown-display-pres clicked cl-dd">
                                                                <span className="selected-value-pres">{selectedValueRightAxis}</span>
                                                                <i aria-hidden="true" className={`fa-solid fa-angle-${isOpenRightAxis ? 'up' : 'down'} cl-dd`} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {isOpenRightAxis && (
                                                    <div className="dropdown-list-pres negative positive both" style={{ zIndex: "1" }}>
                                                        <div className="cl-power-list">
                                                            <div className="top-pres-Zero">0</div>
                                                            <div className="list-container" style={{ display: 'flex' }}>
                                                                <div className="negative-list" style={{ flex: 1 }}>
                                                                    <ul className="list pull-left zero full-width">
                                                                        <li onClick={() => handleSelectRightAxis('10')}>10</li>
                                                                        <li onClick={() => handleSelectRightAxis('20')}>20</li>
                                                                        <li onClick={() => handleSelectRightAxis('30')}>30</li>
                                                                        <li onClick={() => handleSelectRightAxis('40')}>40</li>
                                                                        <li onClick={() => handleSelectRightAxis('50')}>50</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                            <td className="table-prescription-data">
                                                <div className="dd-container-pres">
                                                    <div className="custom-dropdown-pres cl-dd">
                                                        <div
                                                            className={`cl-dd dropdown-container-pres ${isOpenLeftAxis ? 'show' : ''}`}
                                                            onClick={toggleDropdownLeftAxis}
                                                        >
                                                            <div className="dropdown-display-pres clicked cl-dd">
                                                                <span className="selected-value-pres">{selectedValueLeftAxis}</span>
                                                                <i
                                                                    aria-hidden="true"
                                                                    className={`fa-solid fa-angle-${isOpenLeftAxis ? 'up' : 'down'} cl-dd`}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {isOpenLeftAxis && (
                                                    <div className="dropdown-list-pres negative positive both" style={{ zIndex: "1" }} >
                                                        <div className="cl-power-list">
                                                            <div className="top-pres-Zero">0</div>
                                                            <div className="list-container" style={{ display: 'flex' }}>
                                                                <div className="negative-list" style={{ flex: 1 }}>
                                                                    <ul className="list pull-left zero full-width">
                                                                        <li onClick={() => handleSelectLeftAxis('10')}>10</li>
                                                                        <li onClick={() => handleSelectLeftAxis('20')}>20</li>
                                                                        <li onClick={() => handleSelectLeftAxis('30')}>30</li>
                                                                        <li onClick={() => handleSelectLeftAxis('40')}>40</li>
                                                                        <li onClick={() => handleSelectLeftAxis('50')}>50</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </table>

                        <div className="flex-cyl-power">
                            <div>
                                <input className="cyl-checkbox" type="checkbox" onChange={handleCheckBox} />
                            </div>
                            <div className="cyl-power-pres">I Have Cylindrical (CYL) Power</div>
                        </div>
                        <div className="submit-eye-power">
                            <button className="btn-pay-now submit-btn-eye" onClick={handleSubmitClick}>SUBMIT EYE-POWER</button>
                        </div>
                    </div>
                )}


                {showTable && (
                    <div>
                        <div className="right-checked-circle"><i class="fa-regular fa-circle-check"></i></div>
                        <div className="review-submit">Please review your prescription before submitting</div>
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead className="header-prescription-eye">
                                <tr>
                                    <th className="header-left-right-eye">EYE</th>
                                    <th className="header-left-right-eye">RIGHT EYE</th>
                                    <th className="header-left-right-eye">LEFT EYE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(row => (
                                    <tr key={row.id}>
                                        <td className="table-left-right-data">{row.eye}</td>
                                        <td className="table-prescription-data">{row.rightEye}</td>
                                        <td className="table-prescription-data">{row.leftEye}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="continue-btn">
                            <button className="btn-pay-now continue-btn-select" onClick={onContinue}>CONTINUE</button>
                        </div>
                        <div className="back-color-mdl" onClick={handleBackClick}>
                            BACK
                        </div>
                    </div>
                )}
            </div>

        </div>

    );
};

export default ModelPrescriptionManually;
