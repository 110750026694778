import React, { useState, useEffect, useContext } from 'react'
import Card from './Card';
import Spex1 from "../../Assets/Image_lc/a2.webp";
import Spex2 from "../../Assets/Image_lc/b2.webp";
import Spex3 from "../../Assets/Image_lc/d.webp";
import Spex4 from "../../Assets/Image_lc/d2.webp";
import Spex5 from "../../Assets/Image_lc/e2.webp";
import Spex6 from "../../Assets/Image_lc/prog11.webp";
import ItemGroupAPI from "../../../API/API_SERVISES/ItemGroup"
import MyContext from '../../MyContext';

const ProductPriceCarousel = (props) => {
    const { currentUser, itemGroupData } = useContext(MyContext)

    const [primaryGroupData, setPrimaryGroupData] = useState([])

    const fetchData = () => {
        if (currentUser === true) {
            try {
                // const categoryName = await ItemGroupAPI.GetItemGroup();
                const categoryName = itemGroupData;
                const categoryData = categoryName.filter(item => item.primaryGroup === true && item.showInMenu === true && item.imageShowOnError == 2).slice().sort((a, b) => a.displayOrder - b.displayOrder)
                setPrimaryGroupData(categoryData);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };
    useEffect(() => {
        fetchData();
    }, [itemGroupData, currentUser]);


    console.log(primaryGroupData)

    // return (

    //     <div className="cards" >
    //         {primaryGroupData && primaryGroupData.map((item, index) => (
    //             <Card key={index} item={item} image={Spex1} />
    //         ))
    //         }

    //         {/* <Card name={"Computer Glasses"} image={Spex2} />
    //                 <Card name={"Sun Glasses"} image={Spex3} />
    //                 <Card name={"Power Glasses"} image={Spex4} />
    //                 <Card name={"Power Glasses"} image={Spex5} />
    //                 <Card name={"Power Glasses"} image={Spex6} /> */}
    //     </div >

    // )


    return (
        primaryGroupData && primaryGroupData.length > 0 && (
            <div className="cards">
                {primaryGroupData.map((item, index) => (
                    <Card key={index} item={item} image={Spex1} />
                ))}
            </div>
        )
    );
}

export default ProductPriceCarousel