import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "../../Assets/Image_lc/banner/image1.png";
import image2 from "../../Assets/Image_lc/banner/image2.png";
import image3 from "../../Assets/Image_lc/banner/image3.png";
import image4 from "../../Assets/Image_lc/banner/image4.png";
import image5 from "../../Assets/Image_lc/banner/image5.png";
import image6 from "../../Assets/Image_lc/banner/image6.png";
import "../../Css/Store.scss";



const images = [image1, image2, image3, image4, image5, image6];

const CarouselBanner = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        // <div className="carousel-banner outline-none mb-3 max-w-[730px]  ">
        //     <div className="w-full outline-none h-[50vw] md:h-[362px] max-w-[730px] mx-auto">
        //         <Slider {...settings}>
        //             {images.map((image, index) => (
        //                 <div className={``} key={index}>
        //                     <img src={image} alt={`Slide ${index + 1}`} className="w-full outline-none h-full border rounded-3xl object-cover" />
        //                 </div>
        //             ))}
        //         </Slider>
        //     </div>
        // </div>

        <div className="carousel-banner-container">
            <div className="carousel-wrapper">
                <Slider {...settings}>
                    {images.map((image, index) => (
                        <div className="carousel-slide" key={index}>
                            <img src={image} alt={`Slide ${index + 1}`} className="carousel-image" />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>

    );
};

export default CarouselBanner;
