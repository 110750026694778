import React, { useContext, useEffect, useState } from "react";
import ProductSwap2 from "../../Component/ProductSlider/ProductSwap2";
import ProductSwap3 from "../../Component/ProductSlider/ProductSwap3";
import { Link } from "react-router-dom";
import ProductSwap from "../../Component/ProductSlider/Productswap";
import ProductSwap4 from "../../Component/ProductSlider/Productswap4";
import Banner1 from "../../Component/Sections/Banner1";
import ReviewAndRating from "../../Component/Rating/ReviewAndRating";
// import ProductCart1 from "../../Component/Card/ProductCart1";
import SimilerProductSection1 from "../../Component/Sections/SimilerProductSection1";
import ProductCart2 from "../../Component/Card/ProductCart2";
import MyContext from "../../../MyApp/MyContext";
import ProductEnquiryModal from "../../Component/Modal/ProductEnquiryModal";
// import ProductSwap from "../Component/ProductSlider/Productswap";
import { useLocation } from "react-router-dom";
import Order from "../../../API/API_SERVISES/Order.js";
import AlertSuccess from "../../Component/AlertBox/AlertSuccess.jsx";
import img1 from "./../../Assets/Image_lc/ProductLens/crystal-full-rim-wayfarer-lenskart-hustlr-lh-e16985-w-c5-eyeglasses_img_2262_14march24.webp";
import img2 from "./../../Assets/Image_lc/ProductLens/lenskart-air-la-e15417-w-c1-eyeglasses_csvfile-1701688470121-img_7893.webp";
import img3 from "./../../Assets/Image_lc/ProductLens/olive-full-rim-wayfarer-lenskart-hustlr-lh-e16985-w-c2-eyeglasses_img_2273_14march24.webp";
import img4 from "./../../Assets/Image_lc/ProductLens/transparent-gold-full-rim-rectangle-lenskart-air-essentials-la-e13517-c2-eyeglasses_csvfile-1695816767916-g_7546_27_09_2023.webp";
import Slider from "react-slick";
import ProductSwap_LC from "../../Component/ProductSlider/ProductSwap_LC.jsx";
import ModalTechnicalInforamation from "../../Component/Modal/ModalTechnicalInforamation.jsx";
import img5 from "../../Assets/Image_lc/visit_store.webp"
import ItemMasterApi from "../../../API/API_SERVISES/ItemMaster.js";
import img11 from "./../../Assets/Image_lc/LensImg/single_vision.webp"
import img22 from "./../../Assets/Image_lc/LensImg/bifocal.webp"
import img33 from "./../../Assets/Image_lc/LensImg/zero_power.webp"
import img44 from "./../../Assets/Image_lc/LensImg/tinted_sv.webp"
import ThemeModal from "./ThemeModal.jsx";

const ProductDetailTheme1CL = () => {
    const { productEnquiry, openproductenquiry, fetchDataWish } = useContext(MyContext);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const location = useLocation();
    const { item } = location.state;
    const { imageBaseUrl } = useContext(MyContext);

    const [activeComponent, setActiveComponent] = useState(
        <ProductSwap items={item} />
    );
    const [description, setDescription] = useState(true);
    const [features, setFeatures] = useState(true);
    const [specification, setSpecification] = useState(true);
    const [review, setReview] = useState(true);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [isModalStationMaster, setIsModalStationMaster] = useState(false);
    const [modalBox, setModalBox] = useState(false);
    const [isSliderOpen, setSliderOpen] = useState(false);

    const { wishList, setWishList } = useContext(MyContext);

    const toggleWishlist = async (itemData) => {

        console.log(itemData);
        console.log(item);
        console.log(wishList);
        const existingIndex = wishList.find(product => product.d1ItemId === item.d1Id);
        console.log(existingIndex);
        if (existingIndex !== undefined) {
            // alert("Item is already in wishlist");
            try {
                const val = await Order.deleteDeleteFavouriteItemList(existingIndex.id);
                if (Boolean(val) === true) {
                    // alert("Delete data successfully", "Ok");
                    fetchDataWish()
                } else {
                    alert("Error Deleting Data");
                }
                // fetchData();
            } catch (error) {
                console.error("Error deleting account:", error);
            }
            return;
        } else {

            const data = {
                d1ItemId: item.d1Id,
                d2ItemId: item.d2Id,
                d1AccountId: 0,
                d2AccountId: 0,
                userId: 1,
            };
            try {
                const isSuccess = await Order.insertCreateFavouriteItemList(data);
                if (isSuccess) {
                    // alert("Added to wishlist successfully");
                    fetchDataWish()
                } else {
                    throw new Error("Error while adding to wishlist");
                }
            } catch (error) {
                console.error("Error while adding to wishlist:", error);
                alert("Error while adding to wishlist");
            }
            setInputValues({
                d1ItemId: "",
                d2ItemId: "",
                d1AccountId: "",
                d2AccountId: "",
                userId: "",
            });
        };
    }


    const [selectedRadio, setSelectedRadio] = useState("table1");
    const handleRadioChange = (event) => {
        setSelectedRadio(event.target.value);
        // const value = event.target.value;
        // setSelectedRadio(value);
        // navigateToPage(value);
        // setSingleLensModalBox(true)
    };

    //********************************************************** */
    const contactLensData = [
        { label: "Spherical Power (SPH)", rightEye: "-0.75", leftEye: "-1.00" },
        { label: "Cylindrical Power (CYL)", rightEye: "-0.75", leftEye: "-1.00" },
        { label: "Axis (0 - 180)", rightEye: "-0.75", leftEye: "-1.00" }
    ];


    //*********************************************************** */
    const [intervalValuesSphRight, setIntervalValuesSphRight] = useState([]);
    const [intervalValuesSphLeft, setIntervalValuesSphLeft] = useState([]);
    const [intervalValuesCylRight, setIntervalValuesCylRight] = useState([]);
    const [intervalValuesCylLeft, setIntervalValuesCylLeft] = useState([]);
    const [intervalValuesAxisRight, setIntervalValuesAxisRight] = useState([]);
    const [intervalValuesAxisLeft, setIntervalValuesAxisLeft] = useState([]);
    const [intervalValuesBcRight, setIntervalValuesBcRight] = useState([]);
    const [intervalValuesBcLeft, setIntervalValuesBcLeft] = useState([]);
    const [intervalValuesDiaRight, setIntervalValuesDiaRight] = useState([]);
    const [intervalValuesDiaLeft, setIntervalValuesDiaLeft] = useState([]);
    const [intervalValuesAddRight, setIntervalValuesAddRight] = useState([]);
    const [intervalValuesAddLeft, setIntervalValuesAddLeft] = useState([]);

    const [isHidden, setIsHidden] = useState(false);
    const [optionsSph, setOptionsSph] = useState([]);
    console.log(optionsSph);
    // const {rightEyeInputs, setRightEyeInputs} = useState();



    const [formData, setFormData] = useState({
        boxesLeft: '',
        boxesRight: '',
        sphLeft: '',
        sphRight: '',
        cylLeft: '',
        cylRight: '',
        axisLeft: '',
        axisRight: '',
        bcLeft: '',
        bcRight: '',
        diaLeft: '',
        diaRight: '',
        addLeft: '',
        addRight: '',


    });

    function isNegative(num) {
        return num < 0;
    }

    // function generateIntervalValues(value1, value2, interval) {
    //     const val1 = parseFloat(value1)
    //     const val2 = parseFloat(value2)
    //     const interval1 = parseFloat(interval)

    //     const values = [];
    //     for (let i = val1; i <= val2; i += interval1) {
    //         if (isNegative(i)) {
    //             values.push({ value: i });
    //         }
    //         else {
    //             values.push({ value: `+${i}` });
    //         }
    //     }
    //     return values;
    // }


    function generateRanges(sp1, sp2, intervals) {
        // const sp1 = "-1,-3,4";
        // const sp2 = "1,-2,5";
        // const intervals = "0.50,0.25,0.50"; 

        const sp1Array = sp1.split(",").map(Number);
        const sp2Array = sp2.split(",").map(Number);
        const intervalArray = intervals.split(",").map(parseFloat);

        // Initialize an array to store all generated ranges
        const allRanges = [];

        // Iterate over each interval value
        for (let i = 0; i < intervalArray.length; i++) {
            const currentInterval = intervalArray[i];
            const range = generateRange(sp1Array[i], sp2Array[i], currentInterval);
            allRanges.push(...range);
        }
        allRanges.sort((a, b) => a - b);

        console.log("allRanges", allRanges);
        return allRanges;
    }

    function generateRange(start, end, interval) {
        // Convert interval to number
        const step = parseFloat(interval);

        // Initialize the result array
        const result = [];

        // Start iterating from the start value to the end value
        for (let value = start; value <= end; value += step) {
            // Round the value to handle floating point precision issues
            const roundedValue = Math.round(value * 100) / 100;
            // Push the rounded value to the result array
            result.push(roundedValue);
        }

        return result;
    }

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         [name]: value,
    //     }));
    // };

    function generateIntervalValues(inputString) {
        return inputString.split(",").map(item => item.trim());
    }


    //************************************************** */  


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };








    const optionsBoxes = [
        { value: '0 Box', label: '0 Box' },
        { value: '1 Box', label: '1 Box' },
        { value: '2 Box', label: '2 Box' },
        { value: '3 Box', label: '3 Box' },
    ];

    //   const optionsSph = [
    //     { value: '', label: 'Please Select' },
    //     { value: '-0.50', label: '-0.50' },
    //     { value: '-1.00', label: '-1.00' },
    //     { value: '-1.50', label: '-1.50' },
    //   ];

    const shouldHide = formData.axisLeft === null && formData.axisRight.length === 0;


    const toggleSlider = () => {
        // console.log(editData);
        setSliderOpen(true);
    };
    const toggleSliderClose = () => {
        // console.log(editData);
        setSliderOpen(false);
    };

//********************************************************* */
    const opendesc1 = () => {
        setDescription(!description);
    };
    const opendesc2 = () => {
        setFeatures(!features);
    };
    const opendesc3 = () => {
        setSpecification(!specification);
    };

    const opendesc4 = () => {
        setReview(!review);
    };

    const showComponent = (component) => {
        setActiveComponent(component);
        setModalBox(true);
    };

    //******************************************************* */
    const [isModalOpenSaved, setIsModalOpenSaved] = useState(false);
    const [isModalOpenManually, setIsModalOpenManually] = useState(false);

    const handleRadioChangeSaved = () => {
        setIsModalOpenSaved(true);
        setIsModalOpenManually(false);
    };

    const handleRadioChangeManually = () => {
        setIsModalOpenSaved(false);
        setIsModalOpenManually(true);
    };

    const handleRadioChangePower = () => {
        setIsModalOpenSaved(false);
        setIsModalOpenManually(false);
    };

    //*************************************************** */
    const [showModalTechnical, setShowModalTechnical] = useState(false);

    const toggleModal = () => {
        setShowModalTechnical(!showModalTechnical);
    };

    const [showModalVisit, setShowModalVisit] = useState(false);

    const toggleModalVisit = () => {
        setShowModalVisit(!showModalVisit);
    };

    const [showModalCheck, setShowModalCheck] = useState(false);

    const toggleModalCheck = () => {
        setShowModalCheck(!showModalCheck);
    };

    const [showModalReview, setShowModalReview] = useState(false);

    const toggleModalReview = () => {
        setShowModalReview(!showModalReview);
    };

    //***************************************************************** */

    const [isModalOpenInfo, setIsModalOpenInfo] = useState(false);

    const toggleModalInfo = () => {
        setIsModalOpenInfo(!isModalOpenInfo);
    };

    const closeInfoModal = () => {
        setIsModalOpenInfo(false);
    };

    //*************************************************************** */

    

    const [inputValues, setInputValues] = useState({
        d1ItemId: item.d1ItemId,
        d2ItemId: item.d2ItemId,
        d1AccountId: 0,
        d2AccountId: 0,
        userId: item.userId,
        quantity: 1,
        unit: "Pcs",
        itemPrice: item.itemPrice,
    });

    const [modelOpen, setModelOpen] = useState(false);

    const AddToCart = () => {
        console.log();
        console.log(item);
        const data = {
            d1ItemId: item.d1Id,
            d2ItemId: item.d2Id,
            d1AccountId: 0,
            d2AccountId: 0,
            userId: 0,
            quantity: 1,
            unit: "Pcs",
            itemPrice: item.salePrice,
        };
        const isSuccess = Order.insertCreateEcomCartDetail(data);
        setInputValues(isSuccess);
        setAlertSuccess(true);
        setInputValues({
            d1ItemId: " ",
            d2ItemId: " ",
            d1AccountId: " ",
            d2AccountId: " ",
            userId: " ",
            quantity: " ",
            unit: " ",
            itemPrice: " ",
        });
    };

    useEffect(() => {
        if (alertSuccess) {
            const timeoutId = setTimeout(() => {
                setAlertSuccess(false);
            }, 500); // 3 seconds
            return () => clearTimeout(timeoutId);
        }
    }, [alertSuccess]);

    const images = [
        `${imageBaseUrl}${item.images[0]}`,
        `${imageBaseUrl}${item.images[1]}`,
        `${imageBaseUrl}${item.images[2]}`,
        `${imageBaseUrl}${item.images[3]}`, // Assuming imageBaseUrl is defined somewhere
        // img1,
        // img2,
        // img3,
        // img4,
        // Add more image URLs as needed
    ];
    const [components, setComponents] = useState([]); // State to hold the components

    // Function to show the component when clicked
    const showComponent1 = (component) => {
        setComponents([component]); // Set the component to display
        setModalBox(true);
    };

    const handleModalClick = (e) => {
        // Prevent the event from propagating to parent elements
        e.stopPropagation();
    };
    const hideModal3 = () => {
        setModalBox(false);
    };

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };

    const ChangeImage = (index) => {
        setImageIndex(index);
    };

    const [imageIndex, setImageIndex] = useState(0);


    useEffect(() => {
        const fetchData = async () => {
            const contactlens = await ItemMasterApi.GetContactLens(item.d2Id);
            setOptionsSph(contactlens)
            console.log("contactlens", contactlens);
            console.log(item);
            if (item.productCategory == 2) {


                //*********** Sph ****************** */
                const DataSphRight = generateRanges((contactlens.r_SPH_From), (contactlens.r_SPH_To), contactlens.r_SPH_Interval);
                setIntervalValuesSphRight(DataSphRight)

                const DataSphLeft = generateRanges((contactlens.l_SPH_From), (contactlens.l_SPH_To), contactlens.l_SPH_Interval);
                setIntervalValuesSphLeft(DataSphLeft)

                //************** Cyl ****************** */

                const DataCylRight = generateRanges((contactlens.r_CYL_From), (contactlens.r_CYL_To), contactlens.r_CYL_Interval);
                setIntervalValuesCylRight(DataCylRight)

                const DataCylLeft = generateRanges((contactlens.l_CYL_From), (contactlens.l_CYL_To), contactlens.l_CYL_Interval);
                setIntervalValuesCylLeft(DataCylLeft)


                //***************** Axis ******************* */
                const DataAxisRight = generateRanges((contactlens.r_AXIS_From), (contactlens.r_AXIS_To), contactlens.r_AXIS_Interval);
                setIntervalValuesAxisRight(DataAxisRight)

                const DataAxisLeft = generateRanges((contactlens.l_AXIS_From), (contactlens.l_AXIS_To), contactlens.l_AXIS_Interval);
                setIntervalValuesAxisLeft(DataAxisLeft)


                //******************* Bc ************************ */

                const DataBcRight = generateIntervalValues((contactlens.r_BC));
                setIntervalValuesBcRight(DataBcRight)


                const DataBcLeft = generateIntervalValues((contactlens.l_BC));
                setIntervalValuesBcLeft(DataBcLeft)

                //********************** Dia ***************************** */

                const DataDiaRight = generateIntervalValues((contactlens.r_Dia));
                setIntervalValuesDiaRight(DataDiaRight)

                const DataDiaLeft = generateIntervalValues((contactlens.l_Dia));
                setIntervalValuesDiaLeft(DataDiaLeft)

                //************************* Add ****************************** */

                const DataAddRight = generateRanges((contactlens.r_Add_From), (contactlens.r_Add_To), "0.25");
                setIntervalValuesAddRight(DataAddRight)

                const DataAddLeft = generateRanges((contactlens.l_Add_From), (contactlens.l_Add_To), "0.25");
                setIntervalValuesAddLeft(DataAddLeft)
            }

        };
        fetchData();
    }, []);


    const [SingleLensModalBox, setSingleLensModalBox] = useState(false)
    const showModal1 = () => {
        setSingleLensModalBox(true)
    }

    const [showFirstComponent, setShowFirstComponent] = useState(true);
    const [transition, setTransition] = useState(false);

    const handleClick = () => {
        setTransition(true);
        setTimeout(() => {
            setShowFirstComponent(false);
            setTransition(false);
        }, 500); // Duration of the slide effect should match the CSS transition duration
    };

    return (
        <div>
            <div className="ProductDetailPage">
                <div className="ProductDetailPage-left-theme3">
                    <div className="ProductDetailPage-imagebox2 ProductDetailPage-imagebox2-theme3">

                        <div
                            className="lenses-show-multiple"
                        >
                            {images.map((imageUrl, index) => (
                                <>
                                    <div key={index}
                                        onClick={() =>
                                            showComponent1(
                                                <ProductSwap_LC
                                                    items={item}
                                                    imageIndex={imageIndex}
                                                />
                                            )
                                        }
                                        className="ProductDetailPage-smallimg-theme3"
                                    >
                                        <img
                                            className="ProductDetailPage-image"
                                            src={imageUrl}
                                            alt=""
                                        />
                                    </div>
                                </>
                            ))}
                            {/* Render the currently selected component */}
                            {modalBox && (
                                <div onClick={hideModal3} className="modal-back">
                                    <div
                                        onClick={handleModalClick}
                                        style={{ zIndex: "5" }}
                                        className="OpticalField-modal increase_modal"
                                    >
                                        <div style={{ padding: "15px" }}>
                                            <div className="Modal-Display">
                                                <label
                                                    className="close_modal-theme3"
                                                    onClick={hideModal3}
                                                >
                                                    <i class="fa-solid fa-xmark"></i>
                                                </label>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyItems: "baseline",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <div className="ing-size-xx-xl-theme3">
                                                    {/* {activeComponent} */}

                                                        <ProductSwap_LC
                                                            items={item}
                                                            imageIndex={imageIndex}
                                                        />
                                                    </div>
                                                </div>


                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <div className=" ProductDetailPage-imagebox1-theme3">
                                                    <div className="Flex-ImagesTheme_Lc">
                                                        {images.map((imageUrl, index) => (
                                                            <div key={index}>
                                                                <div
                                                                    onClick={() => ChangeImage(index)}
                                                                    className="ProductDetailPage-smallimg-theme lense-view-container-theme3"
                                                                >
                                                                    <img
                                                                        className="ProductDetailPage-image"
                                                                        src={imageUrl}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {/* <div
                                                        onClick={() => showComponent(<ProductSwap items={item}/>)}
                                                        className="ProductDetailPage-smallimg-theme lense-view-container-theme3"
                                                    >
                                                        <img
                                                            className="ProductDetailPage-image"
                                                            src={`${imageBaseUrl}${item.images[0]}`}
                                                            alt=""
                                                        />
                                                    </div> */}
                                                    {/* <div
                                                        onClick={() => showComponent(<ProductSwap2 items={item}/>)}
                                                        className="ProductDetailPage-smallimg-theme lense-view-container-theme3"
                                                    >
                                                        <img
                                                            className="ProductDetailPage-image"
                                                            src={`${imageBaseUrl}${item.images[1]}`}
                                                            alt=""
                                                        />
                                                    </div> */}
                                                    {/* <div
                                                        onClick={() => showComponent(<ProductSwap3 items={item}/>)}
                                                        className="ProductDetailPage-smallimg-theme lense-view-container-theme3"
                                                    >
                                                        <img
                                                            className="ProductDetailPage-image"
                                                            src={`${imageBaseUrl}${item.images[2]}`}
                                                            alt=""
                                                        />
                                                    </div> */}
                                                    {/* <div
                                                        onClick={() => showComponent(<ProductSwap4 items={item}/>)}
                                                        className="ProductDetailPage-smallimg-theme lense-view-container-theme3"
                                                    >
                                                        <img
                                                            className="ProductDetailPage-image"
                                                            src={`${imageBaseUrl}${item.images[3]}`}
                                                            alt=""
                                                        />
                                                    </div> */}
                                                    {/* <div
                                                        onClick={() => showComponent(<ProductSwap items={item}/>)}
                                                        className="ProductDetailPage-smallimg-theme lense-view-container-theme3"
                                                    >
                                                        <img
                                                            className="ProductDetailPage-image"
                                                            src={`${imageBaseUrl}${item.images[4]}`}
                                                            alt=""
                                                        />
                                                    </div> */}
                                                    {/* <div
                                                        onClick={() => showComponent(<ProductSwap2 items={item}/>)}
                                                        className="ProductDetailPage-smallimg-theme lense-view-container-theme3"
                                                    >
                                                        <img
                                                            className="ProductDetailPage-image"
                                                            src={`${imageBaseUrl}${item.images[5]}`}
                                                            alt=""
                                                        />
                                                    </div> */}
                                                    {/* <div
                                                        onClick={() => showComponent(<ProductSwap3 items={item}/>)}
                                                        className="ProductDetailPage-smallimg-theme lense-view-container-theme3"
                                                    >
                                                        <img
                                                            className="ProductDetailPage-image"
                                                            src={`${imageBaseUrl}${item.images[6]}`}
                                                            alt=""
                                                        />
                                                    </div> */}
                                                    {/* <div
                                                        onClick={() => showComponent(<ProductSwap4 items={item}/>)}
                                                        className="ProductDetailPage-smallimg-theme lense-view-container-theme3"
                                                    >
                                                        <img
                                                            className="ProductDetailPage-image"
                                                            src={`${imageBaseUrl}${item.images[7]}`}
                                                            alt=""
                                                        />
                                                    </div> */}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="ProductDetailPage-right">
                    <div className="productdetailbox">
                        <div className="Flex-WishList-Lc">
                        <div>
                            {/* <p className="Product-Font-Top_Lc">Bausch & Lomb iConnect</p> */}
                        </div>
                            <div style={{ cursor: "pointer", position: "absolute", right: "0px", zIndex: "1" }} onClick={() => toggleWishlist(item)}>
                                {(wishList && wishList.find(product => product.d1ItemId === item.d1Id)) ? (
                                    <i className="fa-solid fa-heart HeartSaveBtn2 "></i>
                                ) : (
                                    <i className="fa-regular fa-heart HeartSaveBtn2"></i>
                                )}
                            </div>
                        </div>
                        <p className="productdetail-name font-2">{item.name}</p>
                        {/* <p className="productdetail-name">
                            <div className="product-detail-name">
                                4.7 <i className="fa-solid fa-star product-detail-star"></i>{" "}
                                1507
                            </div>
                        </p> */}
                        <div
                            className="productdetail-name"
                            style={{ display: "flex", alignItems: "center" }}
                        >
                            <p className="product-detail-price">
                                <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                                {item.salePrice}
                            </p>
                            <p className="product-detail-price-2">
                                <i className="fa-solid fa-indian-rupee-sign"></i>{" "}
                                {item.salePrice * 3}
                            </p>
                            <p className="product-detail-return">(₹ 299 With GST)</p>
                        </div>

                        {item.productCategory == "2" ? (
                            <div>
                                <div className="OrderOnPhone-line_Lc"></div>
                                <div className="Order-phone_Lc">ORDER ON PHONE</div>
                                <div className="Give-Missed-Lc">
                                    GIVE MISSED CALL : +918470007367
                                </div>
                                <div className="OrderOnPhone-line_Lc"></div>

                                <div className="Select-Power-Lc">SELECT POWER</div>

                                <div className="Border-Box-Lc">
                                    <div className="Flex-Radio-Button-Lc">
                                        <input
                                            className="Radio-Input-Box-Lc"
                                            type="radio"
                                            name="power"
                                            id=""
                                            onChange={handleRadioChangePower}
                                        ></input>
                                        <p className="product-detail-desc Radio-font-lc">
                                            Call me later for eye power or submit after the checkout
                                        </p>
                                    </div>
                                    <div className="Flex-Radio-Button-Lc">
                                        <input
                                            className="Radio-Input-Box-Lc"
                                            type="radio"
                                            name="power"
                                            onChange={handleRadioChangeSaved}
                                        ></input>
                                        <p className="product-detail-desc Radio-font-lc">
                                            Use Saved Prescription
                                        </p>
                                    </div>
                                    {isModalOpenSaved && (
                                        <div className="Border-Box-Lc">
                                            <div className="Box-padding-themeLc">
                                                <div className="Flex-User-Selected">
                                                    <div className="L-Elipse-Lc">L</div>
                                                    <div>
                                                        <p className="user-lc-font">lens2cart user</p>
                                                        <p className="Added-font-Lc">Added on: 24</p>
                                                        <p className="Added-font-Lc">May 2024</p>
                                                    </div>
                                                    <div>
                                                        <button className="Selected-Btn-Lc">
                                                            {" "}
                                                            <i class="fa-solid fa-circle-check"></i>Selected
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="Border-Box-Lc">
                                                    <table className="Table-Sub-Main_Lc">
                                                        <div className="ContactLens-Font-Lc">CONTACT LENS</div>
                                                        <tr>
                                                            <th>
                                                                <button className="prehistory-btn">
                                                                    Pre. History
                                                                </button>
                                                            </th>
                                                            <th>RIGHT EYE</th>
                                                            <th>LEFT EYE</th>
                                                        </tr>
                                                        {contactLensData.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.label}</td>
                                                                <td>{item.rightEye}</td>
                                                                <td>{item.leftEye}</td>
                                                            </tr>
                                                        ))}
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="Flex-Radio-Button-Lc">
                                        <input
                                            className="Radio-Input-Box-Lc"
                                            type="radio"
                                            name="power"
                                            onChange={handleRadioChangeManually}
                                        ></input>
                                        <p className="product-detail-desc Radio-font-lc">
                                            Enter Prescription Manually
                                        </p>
                                    </div>
                                    {isModalOpenManually && (
                                        <div className="Border-Box-Lc">
                                            <div className="Box-padding-themeLc">
                                                <div>
                                                    <p className="product-detail-desc Radio-font-lc">
                                                        Contact lens power can be different from Eyeglass power
                                                    </p>
                                                </div>

                                                <div className="Flex-Right-Arrow-Lc">
                                                    <p className="product-detail-desc Radio-font-lc Power-Color-Lc">
                                                        LEARN HOW TO READ YOUR PRESCRIPTION
                                                    </p>
                                                    <i className="fa-solid fa-angle-right right-arrow-iconLC"></i>
                                                </div>
                                                <div className="OrderOnPhone-line_Lc"></div>
                                                <div className="Flex-Right-Arrow-Lc">
                                                    <p className="product-detail-desc Radio-font-lc Power-Color-Lc">
                                                        CONVERT EYEGLASSES POWER TO CONTACT LENS
                                                    </p>
                                                    <i className="fa-solid fa-angle-right right-arrow-iconLC"></i>
                                                </div>

                                                <table className="Table-Main_Lc">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <button className="prehistory-btn">
                                                                    Pre. History
                                                                </button>
                                                            </th>
                                                            <th>OS (LEFT EYE)</th>
                                                            <th>OD (RIGHT EYE)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                Boxes
                                                                <i class="fa-regular fa-circle-question question-mark"></i>
                                                                <div className="description">
                                                                    <ul className="ul-padding-left">
                                                                        <li className="li-margin-bot">
                                                                            A box has 6 Lens per box for 1 eye. To learn
                                                                            more, click here
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <select
                                                                    className="Left-Eye-select_lc"
                                                                    name="boxesLeft"
                                                                    value={formData.boxesLeft}
                                                                    onChange={handleChange}
                                                                >
                                                                    {optionsBoxes.map((option, index) => (
                                                                        <option key={index} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select
                                                                    className="Left-Eye-select_lc"
                                                                    name="boxesRight"
                                                                    value={formData.boxesRight}
                                                                    onChange={handleChange}
                                                                >
                                                                    {optionsBoxes.map((option, index) => (
                                                                        <option key={index} value={option.value}>
                                                                            {option.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        {intervalValuesAxisLeft.length != 0 && (
                                                            <tr>
                                                                <td>
                                                                    Sph<i class="fa-regular fa-circle-question "></i>
                                                                    <div className="description">
                                                                        <ul className="ul-padding-left">
                                                                            <li className="li-margin-bot">
                                                                                This is your main power and is a + or - number
                                                                                or 0.To learn more
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="sphLeft"
                                                                        value={formData.sphLeft}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesSphLeft !== null && Array.isArray(intervalValuesSphLeft)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesSphLeft.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="sphRight"
                                                                        value={formData.sphRight}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesSphRight !== null && Array.isArray(intervalValuesSphRight)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesSphRight.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {intervalValuesCylLeft.length != 0 && (
                                                            <tr>
                                                                <td>
                                                                    Cyl<i class="fa-regular fa-circle-question "></i>
                                                                    <div className="description">
                                                                        <ul className="ul-padding-left">
                                                                            <li className="li-margin-bot">
                                                                                This is your main power and is a + or - number
                                                                                or 0.To learn more
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="cylLeft"
                                                                        value={formData.cylLeft}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesCylLeft !== null && Array.isArray(intervalValuesCylLeft)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesCylLeft.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="cylRight"
                                                                        value={formData.cylRight}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesCylRight !== null && Array.isArray(intervalValuesCylRight)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesCylRight.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {intervalValuesAxisLeft.length != 0 && (
                                                            <tr style={{ display: shouldHide ? 'none' : 'table-row' }}>
                                                                <td>
                                                                    Axis<i class="fa-regular fa-circle-question "></i>
                                                                    <div className="description">
                                                                        <ul className="ul-padding-left">
                                                                            <li className="li-margin-bot">
                                                                                This is your main power and is a + or - number
                                                                                or 0.To learn more
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                                <td>


                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="axisLeft"
                                                                        value={formData.axisLeft}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesAxisLeft !== null && Array.isArray(intervalValuesAxisLeft)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesAxisLeft.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>


                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="axisRight"
                                                                        value={formData.axisRight}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesAxisRight !== null && Array.isArray(intervalValuesAxisRight)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesAxisRight.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {intervalValuesBcLeft.length != 0 && (
                                                            <tr>
                                                                <td>
                                                                    Bc<i class="fa-regular fa-circle-question "></i>
                                                                    <div className="description">
                                                                        <ul className="ul-padding-left">
                                                                            <li className="li-margin-bot">
                                                                                This is your main power and is a + or - number
                                                                                or 0.To learn more
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="bcLeft"
                                                                        value={formData.bcLeft}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesBcLeft !== null && Array.isArray(intervalValuesBcLeft)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesBcLeft.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="bcRight"
                                                                        value={formData.bcRight}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesBcRight !== null && Array.isArray(intervalValuesBcRight)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesBcRight.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {intervalValuesDiaLeft.length != 0 && (
                                                            <tr>
                                                                <td>
                                                                    Dia<i class="fa-regular fa-circle-question "></i>
                                                                    <div className="description">
                                                                        <ul className="ul-padding-left">
                                                                            <li className="li-margin-bot">
                                                                                This is your main power and is a + or - number
                                                                                or 0.To learn more
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="diaLeft"
                                                                        value={formData.diaLeft}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesDiaLeft !== null && Array.isArray(intervalValuesDiaLeft)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesDiaLeft.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="diaRight"
                                                                        value={formData.diaRight}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesDiaRight !== null && Array.isArray(intervalValuesDiaRight)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesDiaRight.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}
                                                        {intervalValuesAddLeft.length != 0 && (
                                                            <tr>
                                                                <td>
                                                                    Add<i class="fa-regular fa-circle-question "></i>
                                                                    <div className="description">
                                                                        <ul className="ul-padding-left">
                                                                            <li className="li-margin-bot">
                                                                                This is your main power and is a + or - number
                                                                                or 0.To learn more
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="addLeft"
                                                                        value={formData.addLeft}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesAddLeft !== null && Array.isArray(intervalValuesAddLeft)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesAddLeft.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        className="Left-Eye-select_lc"
                                                                        name="addRight"
                                                                        value={formData.addRight}
                                                                        onChange={handleChange}
                                                                    >
                                                                        <option>--Select--</option>
                                                                        {(() => {
                                                                            if (intervalValuesAddRight !== null && Array.isArray(intervalValuesAddRight)) {  // && Array.isArray(stateInfo)
                                                                                return intervalValuesAddRight.map((option, index) => (
                                                                                    <option key={index} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        })()}
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : null}

                        <div className="productdetail-bottom-box">
                            <div className="product-detail-button-box-theme3 ">
                                <div
                                    // to="/cart2"
                                    onClick={() => AddToCart(item.id)}
                                    className="product-detail-button-theme3"
                                >
                                    Buy Now{" "}
                                </div>
                                {item.productCategory == "1" ? (
                                    <div
                                        onClick={() => toggleSlider()}
                                        // to="/buywithlens"
                                        className="product-detail-button-theme3"
                                    >
                                        Buy With Lens
                                    </div>
                                ) : null}
                            </div>
                            <div className="Flex-Technical-Arrow" onClick={toggleModal}>
                                <div className="Technical-Down-Lc">Technical information</div>
                                <div className="">
                                    {/* <i className="fa-solid fa-angle-down right-arrow-iconLC"></i> */}
                                    <i
                                        className={`fa-solid fa-angle-${showModalTechnical ? "up" : "down"
                                            } right-arrow-iconLC`}
                                    ></i>
                                </div>
                            </div>
                            {showModalTechnical && (
                                <div className="Features-Technical-Box">
                                    <table>
                                        <tr className="">
                                            <td className="Font-TechInfo_Lc">Product id</td>
                                            <td className="Font-TechInfo_Lc">38749</td>
                                        </tr>
                                        <tr className="">
                                            <td className="Font-TechInfo_Lc">Model No.</td>
                                            <td className="Font-TechInfo_Lc">
                                                Bausch & Lomb Purevision2 HD Monthly Disposable
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td className="Font-TechInfo_Lc">Suited For</td>
                                            <td className="Font-TechInfo_Lc"></td>
                                        </tr>
                                        <tr className="">
                                            <td className="Font-TechInfo_Lc">Weight</td>
                                            <td className="Font-TechInfo_Lc"></td>
                                        </tr>
                                        <tr className="">
                                            <td className="Font-TechInfo_Lc">Prescription Type</td>
                                            <td className="Font-TechInfo_Lc"></td>
                                        </tr>
                                        <tr className="">
                                            <td className="Font-TechInfo_Lc">Collection</td>
                                            <td className="Font-TechInfo_Lc"></td>
                                        </tr>
                                        <tr className="">
                                            <td className="Font-TechInfo_Lc">Other Details:</td>
                                            <td className="Font-TechInfo_Lc">
                                                Manufacturer: B&L India Pvt. Ltd. Imported & marketed by
                                                Bausch & Lomb support@lenskart.com. Customer Support:
                                                (0)99998 99998 Name of Product: Purevision 2 HD - 6 Lens
                                                Box. Quantity: 6N MRP: 2300 (Inclusive of all taxes)
                                                Expiry Date: 36 months from Date of manufacturing month.
                                                Country of Origin- USA
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td
                                                className="Font-TechInfo_Lc Color-ShowAllInfo-Lc"
                                                onClick={toggleModalInfo}
                                            >
                                                Show All Information
                                            </td>
                                            <td className="Font-TechInfo_Lc"></td>
                                        </tr>
                                    </table>
                                </div>
                            )}

                            {isModalOpenInfo && (
                                <div onClick={hideModal3} className="modal-back">
                                    <div
                                        onClick={handleModalClick}
                                        style={{ zIndex: "5" }}
                                        className="OpticalField-modal increase_modal"
                                    >
                                        <div style={{ padding: "5px" }}>
                                            <div className="Modal-Title-Lc">Technical information
                                                <label
                                                    className="close_modal-theme3"
                                                    onClick={closeInfoModal}
                                                >
                                                    <i class="fa-solid fa-xmark"></i>
                                                </label>
                                            </div>
                                            <div>
                                                <div className="Modal-Lc-theme3">
                                                    <ModalTechnicalInforamation />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="OrderOnPhone-line_Lc"></div>
                            <div className="Flex-Technical-Arrow" onClick={toggleModalVisit}>
                                <div className="Technical-Down-Lc" >Visit Nearby Store</div>
                                <div className="">
                                    {/* <i className="fa-solid fa-angle-down right-arrow-iconLC"></i> */}
                                    <i className={`fa-solid fa-angle-${showModalVisit ? "up" : "down"
                                            } right-arrow-iconLC`}></i>
                                </div>
                            </div>
                            {showModalVisit && (
                            <div className="Flex-Visit-Store">
                                <div className="Visit-Near-width">
                                    <img src={img5} alt=""/>
                                </div>
                                <div className="Visit-Near-width">
                                    <p>Please Share Your Location To See Nearby Stores</p>
                                    <p className="Color-ShowAllInfo-Lc">Store Locator</p>

                                </div>
                            </div>
                            )}
                            <div className="OrderOnPhone-line_Lc"></div>
                            <div className="Flex-Technical-Arrow" onClick={toggleModalCheck}>
                                <div className="Technical-Down-Lc">Check Delivery Options</div>
                                <div className="">
                                <i className={`fa-solid fa-angle-${showModalCheck ? "up" : "down"
                                            } right-arrow-iconLC`}></i>
                                </div>
                            </div>
                            {showModalCheck && (
                            <div className="Flex-input-box-delivery">
                                <input className="Pincode-width-Lc" type="text" placeholder="Enter Pin Code"/>
                                <button className="Check-Btn-Lc" type="button">CHECK</button>
                            </div>
                            )}
                            <div className="OrderOnPhone-line_Lc"></div>
                            <div className="Flex-Technical-Arrow" onClick={toggleModalReview}>
                                <div className="Technical-Down-Lc">Review(380)</div>
                                <div className="">
                                <i className={`fa-solid fa-angle-${showModalReview ? "up" : "down"
                                            } right-arrow-iconLC`}></i>
                                </div>
                            </div>
                            {showModalReview && (
                            <div className="Contact-Review-Padding-Lc">
                                <div>
                                <i class="fa-solid fa-star star-color-Lc"></i>
                                <i class="fa-solid fa-star star-color-Lc"></i>
                                <i class="fa-solid fa-star star-color-Lc"></i>
                                <i class="fa-solid fa-star star-color-Lc"></i>
                                <i class="fa-solid fa-star star-color-Lc"></i>
                                </div>
                                <div>
                                    <p className="Font-Contact-Lenses_Lc">Contact Lenses</p>
                                    <p>Easy to use and comfortable</p>
                                    <p>CHARMS U. - Jan 18, 2024</p>
                                </div>
                                <div className="Flex-Button-MoreWrite">
                                    <button className="More-Review-btn" type="button">MORE REVIEWS</button>
                                    <button className="write-Review-btn" type="button">WRITE A REVIEW</button>
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="ProductDetailPage-bottom">
                <div className="ProductDetailPage-bottom-1">
                    <div onClick={opendesc1} className="accordion-box">
                        Description
                        {description ? (
                            <div>
                                <i className="fa-solid fa-minus minus-btn"></i>
                            </div>
                        ) : (
                            <div>
                                <i className="fa-solid fa-plus plus-btn"></i>
                            </div>
                        )}
                    </div>
                    {description && (
                        <div className="Description-accordian-box">
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
                                laborum, nemo pariatur minima minus totam sit accusantium
                                impedit sunt mollitia repellat adipisci dolorum iure
                                exercitationem nihil, libero neque recusandae consequatur
                                numquam repellendus. Quibusdam alias placeat animi labore dolor
                                corporis delectus nemo nam quas provident sint sequi, eos,
                                deleniti, perferendis sed.
                            </p>
                        </div>
                    )}
                    <div onClick={opendesc2} className="accordion-box">
                        Features
                        {features ? (
                            <div>
                                <i className="fa-solid fa-minus minus-btn"></i>
                            </div>
                        ) : (
                            <div>
                                <i className="fa-solid fa-plus plus-btn"></i>
                            </div>
                        )}
                    </div>
                    {features && (
                        <div className="Features-accordian-box">
                            <table>
                                <tr className="dark-tablerow">
                                    <td> Frame Material</td>
                                    <td>Metal</td>
                                </tr>
                                <tr className="light-tablerow">
                                    <td> Lens Material</td>
                                    <td>Polycarbonate</td>
                                </tr>
                                <tr className="dark-tablerow">
                                    <td>Frame Color</td>
                                    <td>Black</td>
                                </tr>
                                <tr className="light-tablerow">
                                    <td>Shape</td>
                                    <td>Square</td>
                                </tr>
                                <tr className="dark-tablerow">
                                    <td>Brand</td>
                                    <td>KILLER</td>
                                </tr>
                                <tr className="light-tablerow">
                                    <td>Gender</td>
                                    <td>Men, Women</td>
                                </tr>
                                <tr className="dark-tablerow">
                                    <td>Dimensions</td>
                                    <td>48-20-140</td>
                                </tr>
                                <tr className="light-tablerow">
                                    <td>SKUID</td>
                                    <td>E12B10286</td>
                                </tr>
                                <tr className="dark-tablerow">
                                    <td>Product type</td>
                                    <td>eyeglasses</td>
                                </tr>
                                <tr className="light-tablerow">
                                    <td>Country of Origin</td>
                                    <td>India</td>
                                </tr>
                                <tr className="dark-tablerow">
                                    <td>Manufactured by</td>
                                    <td>Scavin International Exim Pvt. Ltd.</td>
                                </tr>
                                <tr className="light-tablerow">
                                    <td>Sold by</td>
                                    <td>EyeMyEye Pvt Ltd</td>
                                </tr>
                                <tr className="dark-tablerow">
                                    <td>Raw Material Sourced from</td>
                                    <td> India </td>
                                </tr>
                            </table>
                        </div>
                    )}
                    <div onClick={opendesc3} className="accordion-box">
                        Specification
                        {specification ? (
                            <div>
                                <i className="fa-solid fa-minus minus-btn"></i>
                            </div>
                        ) : (
                            <div>
                                <i className="fa-solid fa-plus plus-btn"></i>
                            </div>
                        )}
                    </div>
                    {specification && (
                        <div className="Specification-accordian-box">
                            <p>✔ Full Frame</p>
                            <p>✔ Light Weight and Comfortable</p>
                            <p>✔ Strong and Durable</p>
                            <p>✔ Free Eyeglasses Case with Cleaning Cloth</p>
                        </div>
                    )}

                    <div
                        onClick={opendesc4}
                        className="accordion-box"
                        style={{ borderBottom: "0" }}
                    >
                        Review & Rating
                        {review ? (
                            <div>
                                <i className="fa-solid fa-minus minus-btn"></i>
                            </div>
                        ) : (
                            <div>
                                <i className="fa-solid fa-plus plus-btn"></i>
                            </div>
                        )}
                    </div>
                    {review && <ReviewAndRating />}
                </div>
            </div>
            <Banner1 />
            {/* <SimilerProductSection1
        heading="Similer Products"
        productComponent={<ProductCart2 />}
      /> */}

            {productEnquiry && (
                <ProductEnquiryModal handleClick={openproductenquiry} />
            )}
            {alertSuccess && <AlertSuccess />}

            <div className={`slider-container100 ${isSliderOpen ? "open" : ""}`}>
                <div style={{ padding: "15px" }}>
                    <div>
                        <div className="slider-display-allow">
                            <div className="slider-head-gm"><i class="fa-solid fa-arrow-left-long slide1-left"></i> <h2 className="slider-top-head-nm">
                                Select lens Type </h2></div>
                            <div onClick={toggleSliderClose} className="slider-head-gm"><i class="fa-solid fa-xmark slider-cross-btn"></i></div>
                        </div>
                    </div>


                    <div className="">
                        {showFirstComponent ? (
                            <div className={`component first ${transition ? 'exit' : ''}`} onClick={handleClick}>
                                <div className="buywithlens-content">
                                    <div className="buywithlens-left">
                                        <div
                                            className="buy-with-lens-option-slider-theme3"
                                            onClick={() => showModal1()}  >
                                            <div>
                                                <img src={img11} alt="" className="slider-lens-picture" />
                                            </div>
                                            <div>
                                                <div className="lens-option-top-theme3">
                                                    <p>Single Vision</p>

                                                </div>

                                                <div className="lens-option-pararaph-theme3"
                                                    onClick={() => showModal1()}  >
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
                                                    pariatur ipsum fugit dolor sit amet consectetur adipisicing
                                                    elit.
                                                </div>
                                            </div>
                                            <i class="fa-solid fa-chevron-right"></i>
                                        </div>

                                        <div
                                            className="buy-with-lens-option-slider-theme3"
                                            onClick={() => setSelectedRadio("table2")}
                                        >
                                            <div>
                                                <img src={img22} alt="" className="slider-lens-picture" />

                                            </div>
                                            <div>
                                                <div className="lens-option-top-theme3">
                                                    {/* <input
                                                            type="radio" name=""
                                                            id="" value="table2"
                                                            checked={selectedRadio === "table2"}
                                                            onChange={handleRadioChange}
                                                        /> */}
                                                    <p>Bifocal/Progressive</p>

                                                </div>
                                                <div className="lens-option-pararaph-theme3"
                                                    onClick={() => showModal1()} >
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
                                                    pariatur ipsum fugit sit amet elit.
                                                </div>
                                            </div>
                                            <i class="fa-solid fa-chevron-right"></i>
                                        </div>
                                        <div
                                            className="buy-with-lens-option-slider-theme3"
                                            onClick={() => setSelectedRadio("table3")}
                                        >
                                            <div>
                                                <img src={img33} alt="" className="slider-lens-picture" />

                                            </div>
                                            <div>
                                                <div className="lens-option-top-theme3">
                                                    {/* <input type="radio" name=""
                                                            id="" value="table3"
                                                            checked={selectedRadio === "table3"}
                                                            onChange={handleRadioChange}
                                                        /> */}
                                                    <p>Zero Power</p>

                                                </div>
                                                <div className="lens-option-pararaph-theme3"
                                                    onClick={() => showModal1()} >
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
                                                    pariatur ipsum fugit fugit dolor sit amet .
                                                </div>
                                            </div>
                                            <i class="fa-solid fa-chevron-right"></i>
                                        </div>

                                        <div
                                            className="buy-with-lens-option-slider-theme3"
                                            onClick={() => setSelectedRadio("table4")}
                                        >
                                            <div>
                                                <img src={img44} alt="" className="slider-lens-picture" />
                                            </div>
                                            <div>
                                                <div className="lens-option-top-theme3">
                                                    {/* <input type="radio" name=""
                                                            id="" value="table4"
                                                            checked={selectedRadio === "table4"}
                                                            onChange={handleRadioChange}
                                                        /> */}
                                                    <p>Tinted Single Vision</p>

                                                </div>
                                                <div className="lens-option-pararaph-theme3">
                                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi
                                                    pariatur ipsum fugit fugit dolor sit amet consectetur.
                                                </div>
                                            </div>
                                            <i class="fa-solid fa-chevron-right"></i>
                                        </div>
                                    </div>
                                    {/* <div className="buywithlens-right">
                                    <div className="buy-with-lens-right-content">
                                        {selectedRadio === "table1" && <TableSingleVision onClick={() => handleOptionClick("prescriptionOption")} />}
                                        {selectedRadio === "table2" && <TableBifocal onClick={() => handleOptionClick("prescriptionOption")} />}
                                        {selectedRadio === "table3" && <TablePrograssive onClick={() => handleOptionClick("prescriptionOption")} />}
                                        {selectedRadio === "table4" && <TableZeropower onClick={() => handleOptionClick("prescriptionOption")} />} 
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        ) : (
                            <div className={`component second ${!transition ? 'enter' : ''}`}>
                                <div className="buywithlens-content">
                                    <div className="buywithlens-left">
                                        <div
                                            className="buy-with-lens-option-slider-theme3"
                                        >
                                            <div style={{ width: "100%" }} >
                                                <div className="">
                                                    <div>
                                                        <p className="Anti-class-add">Anti Glare Premium</p>
                                                    </div>
                                                    <div className='slide-mat-desc'>
                                                        <span> 6 Months Warranty</span>
                                                        <span> Double Side Anti - Glare</span>
                                                        <span> 6 Months Warranty</span>
                                                    </div>
                                                    <div className='slide-mat-free-gif'>
                                                        <p>Free Lens</p>
                                                    </div>
                                                    <div className='slide-ofr-sectn-btn'>
                                                        Lens Price: 0 | Free Lens Offer : 200
                                                    </div>
                                                </div>
                                            </div>
                                            <i class="fa-solid fa-chevron-right"></i>
                                        </div>
                                        <div
                                            className="buy-with-lens-option-slider-theme3"
                                        >
                                            <div style={{ width: "100%" }} >
                                                <div className="">
                                                    <div>
                                                        <p className="Anti-class-add">BLU Essential (Free)</p>
                                                    </div>
                                                    <div className='slide-mat-desc'>
                                                        <span> 1 Years Warranty</span>
                                                        <span> Crack smugde & Scratch resistant</span>
                                                        <span> Double Side Anti - Glare</span>
                                                        <span> 1 Years Warranty</span>
                                                        <span>  Blue light  block</span>
                                                    </div>
                                                    <div className='slide-mat-free-gif'>
                                                        <p>Free Lens</p>
                                                    </div>
                                                    <div className='slide-ofr-sectn-btn'>
                                                        Lens Price: 0 | Free Lens Offer : 200
                                                    </div>
                                                </div>
                                            </div>
                                            <i class="fa-solid fa-chevron-right"></i>
                                        </div>
                                        <div
                                            className="buy-with-lens-option-slider-theme3"
                                        >
                                            <div style={{ width: "100%" }} >
                                                <div className="">
                                                    <div>
                                                        <p className="Anti-class-add">BLU Essential (Free)</p>
                                                    </div>
                                                    <div className='slide-mat-desc'>
                                                        <span> 1 Years Warranty</span>
                                                        <span> Crack smugde & Scratch resistant</span>
                                                        <span> Double Side Anti - Glare</span>
                                                        <span> 1 Years Warranty</span>
                                                        <span>  Blue light  block</span>
                                                    </div>
                                                    <div className='slide-mat-free-gif'>
                                                        <p>Free Lens</p>
                                                    </div>
                                                    <div className='slide-ofr-sectn-btn'>
                                                        Lens Price: 0 | Free Lens Offer : 1200
                                                    </div>
                                                </div>
                                            </div>
                                            <i class="fa-solid fa-chevron-right"></i>
                                        </div>
                                        <div
                                            className="buy-with-lens-option-slider-theme3"
                                        >
                                            <div style={{ width: "100%" }} >
                                                <div className="">
                                                    <div>
                                                        <p className="Anti-class-add">Ownday's Clear Vision </p>
                                                    </div>
                                                    <div className='slide-mat-desc'>
                                                        <span> 1 Years Warranty</span>
                                                        <span> Crack smugde & Scratch resistant</span>
                                                        <span> Double Side Anti - Glare</span>
                                                        <span> 1 Years Warranty</span>
                                                        <span>  Blue light  block</span>
                                                    </div>
                                                    <div className='slide-mat-free-gif'>
                                                        <p>Free Lens</p>
                                                    </div>
                                                    <div className='slide-ofr-sectn-btn'>
                                                        Lens Price: 0 | Free Lens Offer : 3200
                                                    </div>
                                                </div>
                                            </div>
                                            <i class="fa-solid fa-chevron-right"></i>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        )}
                    </div>

                    <ThemeModal SingleLensModalBox={SingleLensModalBox} />
                    <div className="slide-call-head-bar"><i class="fa-solid fa-phone-volume"></i>&nbsp;&nbsp;Not sure what to select?&nbsp;&nbsp;&nbsp;&nbsp;
                        <h4>Call 1800-202-4444</h4>
                    </div>
                    <div>
                        <div className="slide-sub-amount-bar">SubTotal (Frame)</div>
                        <div className="slide-sub-amount-bar"><i class="fa-solid fa-indian-rupee-sign"></i> 2000</div>
                    </div>
                    <Link to="/Cart3" onClick={() => AddToCart(item.id)} className="slide-head-bar-td-1">
                        <button className="slide-add-cart-btn">Add To Cart</button>
                    </Link>
                </div>
            </div>



        </div>
    );
};

export default ProductDetailTheme1CL;
