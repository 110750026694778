import React, { useContext } from "react";
import MyContext from "../../MyContext";


const MobileFilterPage = (props) => {

    const { changeProductGrid, changegrid } = useContext(MyContext)

    const FunctionChangeGrid1 = () => {
        changeProductGrid("Tile")
    }
    const FunctionChangeGrid2 = () => {
        changeProductGrid("Grid")
    }
    const FunctionChangeGrid3 = () => {
        changeProductGrid("List")
    }

    return (
        <div className="modal-back">
            <div className="ViewModalBox">
                <i onClick={props.close} class="fa-solid fa-xmark"></i>
                <p>Change View</p>
                <div className="viewmodalbox-Boxes">
                    <div style={{ background: changegrid === "Tile" ? "rgb(226 226 226)" : "white" }} onClick={FunctionChangeGrid1}>Tile View <i class="fa-regular fa-square"></i></div>
                    <div style={{ background: changegrid === "Grid" ? "rgb(226 226 226)" : "white" }} onClick={FunctionChangeGrid2}>Grid View <i class="fa-solid fa-table-cells-large"></i></div>
                    <div style={{ background: changegrid === "List" ? "rgb(226 226 226)" : "white" }} onClick={FunctionChangeGrid3}>List View  <i class="fa-solid fa-grip-lines"></i></div>
                </div>
            </div>
        </div>
    );
};

export default MobileFilterPage;
