import React, { useEffect } from 'react'
import testimg from "../../../../Assets/Image_lc/ProductOne.webp";
import rupayicon from "../../../../Assets/Image_lc/rupee-indian.png"
import "../../../../Css/Store.scss";

const Productcard = ({ product }) => {
    useEffect(() => {
        console.log(product)
    }, [product]);


    // {
    //     name: "Lenskart Air Online",
    //     size: "Narrow",
    //     gender: "Unisex",
    //     price: "2000",
    //     buttonTitle: "Reserve at Store",
    //     image: ""
    // }
    return (
        <div className={`productcard-main`}>
            <div className={`product-image-container`}>
                <img src={testimg} />
            </div>
            <div className={`product-name`}>
                <h2>{product?.name}</h2>
                <div>
                    <p>
                        Size: {product?.size}
                    </p>
                    <p>
                        Gender: {product?.gender}
                    </p>
                </div>
            </div>

            <hr className={`product-hr`} />

            {/* -------------price-and-btn--------------- */}
            <div className={`productprice-btn`}>
                {/* -----------price-------- */}
                <div className={`product-price-store`}>
                    <h2 className='product-price-store-span'>
                        <span>
                            <img src={rupayicon} />
                        </span>
                        {product?.price}
                    </h2>
                    <button className='product-price-store-reserve' >
                        {product?.buttonTitle}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Productcard