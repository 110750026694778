import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import useGeolocation from './UseGeolocation';
import "../../Css/Store.scss";

function MapBlock({ userLocation, selectedmap }) {
  const { position } = useGeolocation();
  const [currentPosition, setCurrentPosition] = useState({ lat: 47.2178, lng: -1.55336 });

  const mapRef = useRef();

  useEffect(() => {
    if (userLocation) {
      setCurrentPosition(userLocation);
    } else if (position) {
      setCurrentPosition(position);
    }
  }, [userLocation, position]);

  console.log(userLocation)

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setView(currentPosition, 13);
    }
  }, [currentPosition]);

  // -------------allstoreaddress------------------------- 

  const Allstore = [
    {
      name: "Kenya",
      iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d530.4185689890259!2d36.82102424959869!3d-1.261360800000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f171bd8368527%3A0xef1bf33a94c0c87!2sPrime%20Bank!5e1!3m2!1sen!2sin!4v1733835044422!5m2!1sen!2sin"
    },
    // {
    //   name: "Jawahar Nagar",
    //   iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d530.4185689890259!2d36.82102424959869!3d-1.261360800000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f171bd8368527%3A0xef1bf33a94c0c87!2sPrime%20Bank!5e1!3m2!1sen!2sin!4v1733835044422!5m2!1sen!2sin"
    // },
    // {
    //   name: "Paschim Vihar",
    //   iframe: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3500.6613995350012!2d77.10373487457433!3d28.66985548238647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a27af074692537b%3A0x5c3cc90ce00cd942!2sLenskart.com%20at%20Paschim%20Vihar!5e0!3m2!1sen!2sin!4v1731479016661!5m2!1sen!2sin"
    // }

  ]

  const [selectediframe, setselectediframe] = useState(null)
  useEffect(() => {
    const findmap = Allstore.find((item) => item.name == selectedmap)
    setselectediframe(findmap?.iframe)
  }, [selectedmap])
  console.log(selectedmap);



  return (
    <div style={{height:'100%'}} >
      <iframe src={selectediframe}
        width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{border: "none"}}></iframe>
    </div>
  );
}

export default MapBlock;
