import React, { useEffect, useState } from 'react'
import img11 from "./../../Assets/Image_lc/LensImg/single_vision.webp"
import img22 from "./../../Assets/Image_lc/LensImg/bifocal.webp"
import img33 from "./../../Assets/Image_lc/LensImg/zero_power.webp"
import img44 from "./../../Assets/Image_lc/LensImg/tinted_sv.webp"
// import LensMasterSettAndFeatures from "./../../Assets/LensMasterSettAndFeatures"
// import LensMasterSettAndFeatures from "../../../API/API_SERVISES/Order"
import Order from '../../../API/API_SERVISES/Order'
// import ThemeModal1 from './ThemeModal1'
import { Link, useLocation } from 'react-router-dom'

const ThemeModal = (props) => {
    const location = useLocation();
    const { item } = location.state || {};
    console.log(item);
    const [apiData, setapiData] = useState([])
    const [apiDataFeature, setapiDataFeature] = useState([])
    const [featurePrice, setFeaturePrice] = useState([])
    const [featurePrice2, setFeaturePrice2] = useState([])
    const [lensCoating, setLensCoating] = useState([])
    const [lensCoatingName, setLensCoatingName] = useState([])


    const fetchData1 = async () => {
        try {
            const val = await Order.getItemRelatedLinkedLensProdSetting(item.d2Id)
            console.log(val);
            setapiData(val);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        fetchData1();
    }, []);

    // const fetchData2 = async () => {
    //     try {
    //         const val = await Order.getLensMasterSettAndFeatures(item.d2Id)
    //         console.log(val);
    //         setapiDataFeature(val);
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     }
    // };
    // useEffect(() => {
    //     fetchData2();
    // }, []);


    useEffect(() => {
        console.log("Api data here", apiData.result)
        console.log("Api data feature", apiDataFeature)
    }, [apiDataFeature])

    const handlemodal = async (id, lensPrice, lensName) => {
        // debugger
        setFeaturePrice(lensPrice)
        setFeaturePrice2(lensName)
        var data = await featuredata(id)
        if (data === true) {
            await props.handleModal(id)
        } else {
            props.handlelastModal(id)
        }
    }

    const addtocart2 = async (id,) => {
        console.log(featurePrice, featurePrice2)
        // debugger
        await props.AddLensInCart(id, featurePrice, featurePrice2)
    }

    const addtocart3 = async (id,) => {
        console.log(featurePrice, featurePrice2)
        // console.log(lensCoating, lensCoatingName)
        // debugger
        await props.AddLensInCart(id, featurePrice, featurePrice2)
    }
    // const addtocart4 = async (id,) => {
    //     console.log(featurePrice, featurePrice2)
    //     console.log(lensCoating, lensCoatingName)
    //     // debugger
    //     await props.AddLensInCart(id, featurePrice, featurePrice2, lensCoating, lensCoatingName)
    // }

    const addtocart4 = async (id) => {
        console.log(featurePrice, featurePrice2);
        console.log(lensCoating, lensCoatingName);

        // debugger

        if (lensCoating.length != 0 && lensCoatingName.length != 0) {
            // alert("coating")   // Check if any of the specified conditions are true
            await props.AddLensInCart(id, featurePrice, featurePrice2, lensCoating, lensCoatingName);
        } else {

            await addtocart2(id);
        }

    }

    const featuredata = async (id) => {
        const data = apiData.result
            .filter(item => item.itemId === id)
            .map(item => item.addFeatures)
            .flat();
        console.log("Featuredata: ", data);
        setapiDataFeature(data);
        return data.length > 0

    }

    const [selectedFeature, setSelectedFeature] = useState([]);

    const handleFeatureClick = (feature, price) => {
        // debugger;
        setLensCoatingName(feature)
        setLensCoating(price)
        setSelectedFeature(feature, price);
        console.log("retertwe", price)
        props.handlelastModal(feature.id);
    };

    const finalPrice = props.price + featurePrice;
    const featurefinalPrice = finalPrice + lensCoating;

    return (
        <div>
            {props.SingleLensModalBox && (
                <div className="buywithlens-content">
                    <div className="buywithlens-left">
                        <div style={{ overflowX: "hidden", overflowY: "scroll", height: "65vh", scrollbarWidth: "thin" }}>
                            {apiData.result && apiData.result.filter(dataitem => dataitem.lensType === `${props.lensType}`).map((dataitem, index) => (
                                <div className="buy-with-lens-option-slider-theme3"
                                    onClick={() => handlemodal(dataitem.itemId, dataitem.price, dataitem.companyTitle)}
                                    key={index}>
                                    <div style={{ width: "100%" }}>
                                        <div>
                                            <div>
                                                <p className="Anti-class-add">{dataitem.companyTitle}</p>
                                            </div>
                                            <div className='slide-mat-desc'>
                                                <div className='slide-mat-desc'>
                                                    <p className="" style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: dataitem.fullDescription }} />
                                                </div>
                                            </div>
                                            <div className='slide-mat-free-gif'>
                                                <p>{dataitem.freeLens}</p>
                                            </div>
                                            <div className='slide-ofr-sectn-btn'>
                                                {`Lens Old Price: ${dataitem.oldPrice} | Free Lens Offer : ${dataitem.price}`}
                                            </div>
                                        </div>
                                    </div>
                                    <i className="fa-solid fa-chevron-right"></i>
                                </div>
                            ))}
                        </div>
                        <div className="cart-btn-price">
                            <div className="slide-head-bar-td-1" >
                                <div className="slide-call-head-bar"><i class="fa-solid fa-phone-volume"></i>&nbsp;&nbsp;Not sure what to select?&nbsp;&nbsp;&nbsp;&nbsp;
                                    <h4>Call 1800-202-4444</h4>
                                </div>
                                <div>
                                    <div className="slide-sub-amount-bar">SubTotal (Frame)</div>
                                    <div className="slide-sub-amount-bar"><i class="fa-solid fa-indian-rupee-sign"></i> {props.price}</div>
                                </div>
                            </div>
                            <div className='cart-button'>
                                <Link to="/Cart3" onClick={() => addtocart2(item.d2Id)} className="slide-head-bar-td-1">
                                    <button className="slide-add-cart-btn">Add To Cart</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }

            {apiDataFeature && apiDataFeature.length > 0 && props.featureData && (
                <>
                    <div className="buywithlens-content">
                        <div className="buywithlens-left">
                            <div style={{ height: "60vh" }}>
                                {apiDataFeature.map((Feature, index) => (
                                    <div
                                        className="buy-with-lens-option-slider-theme3"
                                        key={index}
                                        onClick={() => handleFeatureClick(Feature.features, Feature.price)}
                                    >
                                        <div style={{ width: "100%" }}>
                                            <div>
                                                <div>
                                                    <p className="Anti-class-add">{Feature.features}</p>
                                                </div>
                                                <div className='slide-mat-desc'>
                                                    <div className='slide-mat-desc'>
                                                        <p>{Feature.remarks}</p>
                                                    </div>
                                                </div>
                                                <div className='slide-mat-free-gif'>
                                                    <p>{Feature.freeLens}</p>
                                                </div>
                                                <div className='slide-ofr-sectn-btn'>
                                                    {`Lens Price: ${Feature.price * 2} | Free Lens Offer: ${Feature.price}`}
                                                </div>
                                            </div>
                                        </div>
                                        <i className="fa-solid fa-chevron-right"></i>
                                    </div>
                                ))}
                            </div>

                            <div className="cart-btn-price">
                                <div className="slide-head-bar-td-1">
                                    <div className="slide-call-head-bar">
                                        <i className="fa-solid fa-phone-volume"></i>&nbsp;&nbsp;Not sure what to select?&nbsp;&nbsp;&nbsp;&nbsp;
                                        <h4>Call 1800-202-4444</h4>
                                    </div>
                                    <div>
                                        <div className="slide-sub-amount-bar">SubTotal (Frame)</div>
                                        <div className="slide-sub-amount-bar">
                                            <i className="fa-solid fa-indian-rupee-sign"></i> {finalPrice}
                                        </div>
                                    </div>
                                </div>
                                <div className='cart-button'>
                                    <Link to="/Cart3" onClick={() => addtocart3(item.d2Id)} className="slide-head-bar-td-1">
                                        <button className="slide-add-cart-btn">Add To Cart</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}


            {props.eyePower && (
                <div className="buywithlens-content">
                    <div className="buywithlens-left">
                        {/* {apiDataFeature.addFeatures.map((Feature, index) => ( */}
                        <div class="AboutEyePowerContainer">
                            <div class="Heading">
                                <h1>What About My Eye Power?</h1>
                            </div>
                            <div class="SubHeadingContainer">
                                <div class="RowContainer">
                                    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#737397"></rect>
                                        <path d="M21.333 12 14 19.333 10.666 16" stroke="#fff" stroke-linecap="square">

                                        </path>
                                    </svg>
                                    <div class="SubHeading">You can submit your eye power after Payment step</div>
                                </div>
                                <div class="RowContainer">
                                    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#737397"></rect>
                                        <path d="M21.333 12 14 19.333 10.666 16" stroke="#fff" stroke-linecap="square">
                                        </path>
                                    </svg>
                                    <div class="SubHeading">Power can be submitted within 10 days of order placement
                                    </div>
                                </div>
                                <div class="RowContainer">
                                    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="32" height="32" rx="16" fill="#737397"></rect>
                                        <path d="M21.333 12 14 19.333 10.666 16" stroke="#fff" stroke-linecap="square">
                                        </path>
                                    </svg>
                                    <div class="SubHeading">No additional charges for high/complex power

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cart-btn-price">
                            <div className="slide-head-bar-td-1" >
                                <div className="slide-call-head-bar"><i class="fa-solid fa-phone-volume"></i>&nbsp;&nbsp;Not sure what to select?&nbsp;&nbsp;&nbsp;&nbsp;
                                    <h4>Call 1800-202-4444</h4>
                                </div>
                                <div>
                                    <div className="slide-sub-amount-bar">SubTotal (Frame)</div>
                                    <div className="slide-sub-amount-bar"><i class="fa-solid fa-indian-rupee-sign"></i> {featurefinalPrice}</div>
                                </div>
                            </div>
                            <div className='cart-button'>
                                <Link to="/Cart3" onClick={() => addtocart4(item.d2Id)} className="slide-head-bar-td-1">
                                    <button className="slide-add-cart-btn">Add To Cart</button>
                                </Link>
                            </div>
                        </div>

                    </div>

                </div>

            )}




        </div>
    )
}

export default ThemeModal