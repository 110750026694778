import React, { useEffect, useState,useRef } from "react";
import AddAddress from "../../../API/API_SERVISES/AddAddress";
import StateInfoApi from "../../../API/API_SERVISES/StateInfo";
import { useContext } from "react";
import MyContext from "../../MyContext";

const AddAddressModalProfle = (props) => {
  // const [myAccountID, setMyAccountID] = useContext(MyContext);
  const [myAccountID, setMyAccountID] = useState();


  const handleEscapeKey = () => {
    props.modalclose();
  };

  const [stateInfo, setStateInfo] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await StateInfoApi.GetAllState();
        setStateInfo(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        handleEscapeKey();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    // console.log(loginData.d1Id);
    setMyAccountID(loginData.d1Id);
  }, [myAccountID]);

  const [inputValues, setInputValues] = useState({
    accountId: "",
    name: "",
    mobile: "",
    pincode: "",
    state: "",
    address: "",
    locality: "",
    city: "",
    typeOfAddress: 0,
    isDefault: "",
    isActive: true,
  });

  console.log(props.data)

  useEffect(() => {
    console.log(props.data);
    const fetchData = async () => {
      if (props.data) {
        try {
          const data = await props.data; // Assuming props.editData is a Promise
          setInputValues({
            id: data.id,
            accountId: 707,
            name: data.name,
            mobile: data.mobile,
            pincode: data.pincode,
            state: data.state,
            address: data.address,
            locality: data.locality,
            city: data.city,
            typeOfAddress: data.typeOfAddress,
            isDefault: data.isDefault,
            isActive: true,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else if (props.data === "" || props.data === undefined) { // Use logical OR here
        setInputValues({
          accountId: "",
          name: "",
          mobile: "",
          pincode: "",
          state: "",
          address: "",
          locality: "",
          city: "",
          typeOfAddress: 0,
          isDefault: "",
          isActive: true,
        });
      }
    };
    

    fetchData();
  }, [props.data]);

  const [errorMessages, setErrorMessages] = useState({
    name: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleInputChange1 = (e) => {
    const value = parseInt(e.target.value, 10);
    setInputValues({
      ...inputValues,
      isDefault: value,
      typeOfAddress: Number(e.target.value),
    });
  };

  const handleInputChange2 = (e) => {
    const checked = e.target.checked;
    setInputValues({
      ...inputValues,
      isDefault: checked,
    });
  };

  const validateInput = (fieldName) => {
    // console.log(fieldName);
    const fieldValue = inputValues[fieldName];

    if (
      !fieldValue ||
      (typeof fieldValue === "string" && fieldValue.trim() === "")
    ) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${toFriendlyName(fieldName)} field is required`,
      }));
      return false;
    }
  };
  const fieldNames = [];

  const toFriendlyName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);

  const focusInput = (inputRef) => {
    inputRef.current.focus();
};

  const SaveBtn = async () => {
    console.log(props.data);
    let allValid = true;

    fieldNames.forEach((fieldName) => {
      const isValid = validateInput(fieldName);

      if (!isValid) {
        allValid = false;
      }
    });

    if (allValid) {
      if (props.data) {
        if(inputValues.name === ""){
          alert("Name field is Required")
          focusInput(inputRef1)
        }
        else if(inputValues.address ===""){
          alert("Address field is Required ")
          focusInput(inputRef2)
        }
        else if(inputValues.state === "" && inputValues.state=== 0){
          alert("Please select the state ")
          focusInput(inputRef3)
        }
        else if(inputValues.city === "" ){
          alert("City/District feild is required ")
          focusInput(inputRef4)
        }
        else{
          const address = await AddAddress.updateAddress(
            inputValues,
            myAccountID
          );
          if (address) {
            alert("Address Updated successfully");
            props.modalclose();
            props.fetchAddress();
          } else {
            alert("error while updating");
          }
        }
      } else {
        try {
          if(inputValues.name === ""){
            alert("Name field is Required")
            focusInput(inputRef1)
          }
          else if(inputValues.address ===""){
            alert("Address field is Required ")
            focusInput(inputRef2)
          }
          else if(inputValues.state === "" || inputValues.state === 0){
            alert("Please select the state ")
            focusInput(inputRef3)
          }
          else if(inputValues.city === "" ){
            alert("City/District feild is required ")
            focusInput(inputRef4)
          }
          else{
          const address = await AddAddress.insertAddress(
            inputValues,
            myAccountID
          );

          if (address) {
            alert("Address added successfully");
            props.modalclose();
            props.fetchAddress();
          } else {
            // Handle case when address is not added successfully
          }
        }
        } catch (error) {
          console.error("Error inserting data:", error);
          alert("Error inserting data");
        }
      }
    } else {
      alert("Validation failed");
    }
  };

  return (
    <div className="modal-back">
      <div className="main-modal-mothers-hut ">
        <h5 style={{ color: "white", marginTop: "0", marginBottom: "5px" }}>
          ADD ADDRESS
        </h5>
        <div
          style={{ zIndex: "2" }}
          className="xmark-ms-2"
          onClick={props.modalclose}
        >
          <i className="fa-solid fa-xmark"></i>
        </div>
        <i
          onClick={props.modalclose}
          style={{ color: "black", cursor: "pointer" }}
          className="fa-solid fa-xmark card-menu-favorite dis-non"
        ></i>
        <div className=" address-flex-box">
          <div>
            <label className="addaddress_lable" htmlFor="">
              Name
            </label>
            <input
              className="addaddress_input"
              type="text"
              id=""
              onChange={handleInputChange}
              value={inputValues.name}
              name="name"
              ref={inputRef1}
            />
          </div>
          <div>
            <label className="addaddress_lable" htmlFor="">
              Mobile No
            </label>
            <input
              className="addaddress_input"
              type="number"
              onChange={handleInputChange}
              value={inputValues.mobile}
              name="mobile"
              id=""
            />
          </div>
          <div>
            <label className="addaddress_lable" htmlFor="">
              Pincode
            </label>
            <input
              className="addaddress_input"
              type="number"
              onChange={handleInputChange}
              value={inputValues.pincode}
              name="pincode"
              id=""
            />
          </div>
          <div>
            <label className="addaddress_lable" htmlFor="">
              State
            </label>
            {/* <input
              className="addaddress_input"
              type="text"
              onChange={handleInputChange}
              value={inputValues.state}
              name="state"
              id=""
            /> */}

            <select
              className="select-address"
              onChange={handleInputChange}
              value={inputValues.state}
              name="state"
              id=""
              ref={inputRef3}
            >
              <option >
                Select State
              </option>
              {(() => {
                if (stateInfo !== null && Array.isArray(stateInfo)) {
                  return stateInfo.map((data) => (
                    <option key={data.id} value={data.id}>
                      {data.stateName}
                    </option>
                  ));
                }
              })()}
            </select>
          </div>
          <div>
            <label className="addaddress_lable" htmlFor="">
              Address
            </label>
            <input
              className="addaddress_input"
              type="text"
              onChange={handleInputChange}
              value={inputValues.address}
              name="address"
              id=""
              ref={inputRef2}
            />
          </div>
          <div>
            <label className="addaddress_lable" htmlFor="">
              Locality/Town
            </label>
            <input
              className="addaddress_input"
              type="text"
              onChange={handleInputChange}
              value={inputValues.locality}
              name="locality"
              id=""
            />
          </div>
          <div style={{ height: "45px" }}>
            <label className="addaddress_lable" htmlFor="">
              City/District
            </label>
            <input
              className="addaddress_input"
              type="text"
              onChange={handleInputChange}
              value={inputValues.city}
              name="city"
              id=""
              ref={inputRef4}
            />
          </div>

          <div>
            <label className="addaddress_lable" htmlFor="">
              Type of address
            </label>
            {/* <div1 style={{ display: "flex" }}>
              <div1 className="addaddress_radio_box">
                <input
                  className="addaddress_radio"
                  type="radio"
                  onChange={handleInputChange}
                  value={inputValues.typeOfAddress}
                  name="typeOfAddress"
                  id=""
                />
                <label
                  style={{ marginBottom: "0" }}
                  className="addaddress_lable"
                  htmlFor=""
                >
                  Home
                </label>
              </div1>

              <div1 className="addaddress_radio_box">
                <input
                  className="addaddress_radio"
                  type="radio"
                  onChange={handleInputChange}
                  value={inputValues.typeOfAddress}
                  name="typeOfAddress"
                  id=""
                />
                <label
                  style={{ marginBottom: "0" }}
                  className="addaddress_lable"
                  htmlFor=""
                >
                  Office
                </label>
              </div1>
            </div1> */}

            <div1 style={{ display: "flex" }}>
              <div1 className="addaddress_radio_box">
                <input
                  className="addaddress_radio"
                  type="radio"
                  onChange={handleInputChange1}
                  value={0}
                  checked={inputValues.typeOfAddress === 0}
                  name="typeOfAddress"
                  id="homeRadio"
                />
                <label
                  style={{ marginBottom: "0" }}
                  className="addaddress_lable"
                  htmlFor="homeRadio"
                >
                  Home
                </label>
              </div1>

              <div1 className="addaddress_radio_box">
                <input
                  className="addaddress_radio"
                  type="radio"
                  onChange={handleInputChange1}
                  value={1}
                  checked={inputValues.typeOfAddress === 1}
                  name="typeOfAddress"
                  id="officeRadio"
                />
                <label
                  style={{ marginBottom: "0" }}
                  className="addaddress_lable"
                  htmlFor="officeRadio"
                >
                  Office
                </label>
              </div1>
            </div1>
          </div>
          <div2 className="addaddres-bottom_box">
            <input
              style={{ marginRight: "8px" }}
              type="checkbox"
              onChange={handleInputChange2}
              checked={inputValues.isDefault === true}
              name="typeOfAddress"
              id=""
            />
            <label
              className="addaddress_lable"
              style={{ marginBottom: "0", fontSize: "13px" }}
              htmlFor=""
            >
              Make this as a Defult Address
            </label>
          </div2>

          <button className="main-modal-button-1" style={{color: "white"}} onClick={SaveBtn}>
            ADD ADDRESS
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAddressModalProfle;
