import React from 'react'

const MobileBottomBuyButton = (props) => {
    const { item, AddToCart } = props;
    return (
        <div className="MobileBottomTheme3">
            <div className="MobileBottomTheme3-right">
                <div > <i class="fa-brands fa-whatsapp"></i><label>
                    Buy on
                    <br />
                    Chat
                </label>
                </div>
                <div> <i class="fa-solid fa-house"></i><label>
                    Buy at
                    <br />
                    Home
                </label>
                </div>
                <div> <i class="fa-solid fa-store"></i> <label>
                    Buy at
                    <br />
                    Store
                </label>
                </div>
            </div>
            <button onClick={() => AddToCart(item.id)} className="MobileBottomTheme3-button"> Add To Cart</button>
        </div>

    )
}

export default MobileBottomBuyButton