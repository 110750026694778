import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import MyContext from '../../MyContext';
import ItemMaster from "../../../API/API_SERVISES/ItemMaster";
import useDebounce from "../useDebounce";


const SearchPageMobile = (props) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const { currentUser,
    imageBaseUrl, setClose, ProducrDetailPageURL } = useContext(MyContext);
  var isSearch = true;
  const [Data, setData] = useState([]);
  const [selected, setselected] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  const searchValueFunction = async (value) => {
    debugger
    if (currentUser === true) {
      try {
        setSearchValue(value);
        // console.log(value);
        const filteredData = [
          {
            fieldName: "name",
            operatorName: "contains",
            compareValue: value,
          },
        ];
        // const searchItem = await ItemMaster.GetItemMasterFilter(filteredData);
        const searchItem = await ItemMaster.GetItemMasterFilter(filteredData, 15);
        if (searchItem) {
          const filteredItems = searchItem.filter(item =>
            /^\d+$/.test(item.name) || (item.attributeParentId !== 0 && /[a-zA-Z]/.test(item.name))
          );
          console.log(filteredItems);
          setData(filteredItems)
        }
      } catch (error) {
        console.error('Error in searchValueFunction:', error);
      }
    }
  };

  // useEffect(() => {
  //   searchValueFunction()
  // }, [currentUser])

  const Debounsedvalue = useDebounce(searchValue, 800)

  useEffect(() => {
    if (Debounsedvalue && Debounsedvalue != "") {
      debugger
      searchValueFunction(Debounsedvalue)
    }
  }, [Debounsedvalue])


  const handleInputChange = (e) => {
    debugger
    const newValue = e.target.value;
    setSearchValue(newValue); // Update the input state immediately
    // searchValueFunction(newValue); // Call the debounced search function
  };






  const closeSuggestion = () => {
    debugger
    setTimeout(() => {
      setClose(false);
    }, 100); // 1000 milliseconds = 1 second
  }

  const openSuggestion = () => {
    setClose(true)
  }

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      event.target.blur(); // This will blur the input element
      props.close()
      navigate("/productsearch", { state: { searchValue: searchValue } });
    }
  };


  useEffect(() => {
    const handleKeyDown = (e) => {
      // Handle ArrowDown key
      if (e.key === 'ArrowDown') {
        if (selected === null) {
          setselected(0);
          setClose(true)
          Data.length !== 0 && setSearchValue(Data[0].name);
        } else {
          if (selected < Data.slice(0, 15).length - 1) {
            setClose(true)
            setSearchValue(Data[selected + 1].name);
            setselected(selected + 1);
          }
        }
      }

      // Handle ArrowUp key
      if (e.key === 'ArrowUp') {
        setClose(true)
        if (selected > 0) {
          setClose(true)
          setSearchValue(Data[selected - 1].name);
          setselected(selected - 1);
        }
      }

      // Handle Enter key
      if (e.key === 'Enter') {
        setClose(true)
        if (selected !== null && Data[selected]) {
          // Navigate to the selected item's page
          navigate(`${ProducrDetailPageURL}/${Data[selected].d2Id}`, { state: { isSearch } });
          setClose(false); // Close the Data box
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selected, Data, navigate]);

  return (
    <div className="mobile-filter-page">
      <i onClick={props.close} className="search-back-button fa-solid fa-arrow-left-long"></i>
      <div className='searchpanel-input-box' >
        <input className='search-input-searchpanel' type="text" placeholder='What are you looking for ?' ref={inputRef}
          value={searchValue}
          onBlur={closeSuggestion}
          onChange={(e) => {
            handleInputChange(e)
          }}
          onFocus={openSuggestion}
          onKeyDown={handleKeyDown} />
      </div>
      <div className='search-options-panal'>
        {/* <p className='search-options-panal-txt'>Search</p> */}
        <div className='search-options-panal-recent-box'>
          {Data?.slice(0, 15).map((item, index) => {
            return (
              <div className='search-options-panal-recent-1' style={{ backgroundColor: `${selected === index ? "#ccc" : "white"}` }}>
                <div>
                  <i className="fa-regular fa-clock"></i>
                  {item.name}
                </div>
                {item.images[0] ? (
                  <img style={{ width: "30px", height: "30px", margin: "0px 10px 0px 5px" }} src={`${imageBaseUrl}${item.images[0]}`} alt="" />
                ) : (
                  <div style={{ width: "30px", height: "30px", margin: "0px 10px 0px 5px" }}> </div>
                )}</div>
            )
          })}

        </div>
        <p className='search-options-panal-txt'>Tranding on our shop</p>
        <div className='search-options-panal-recent-box'>
          <div className='search-options-panal-recent-1'>  Eyeglasses</div>
          <div className='search-options-panal-recent-1'> Sunglasses</div>
          <div className='search-options-panal-recent-1'>  lens</div>
        </div>
      </div>

    </div>
  )
}

export default SearchPageMobile
