import React from "react";
import "../../../Css/Store.scss";
import logo from "../../../Assets/Images/logo.jpg"
import { Link } from 'react-router-dom';


function Header() {
  return (
    <div class="header-container1">
      <div class="header-content">
        <Link to="/" class="logo-container">
          <img className="len2cart-logo-size"
            src={logo}
            alt="lens2cart-logo"
          />
        </Link>

        <div class="contact-info">
          <div class="contact-item">
            <img
              src="https://www.lenskart.com/stores/svg/phone-call.svg"
              alt="Call"
            />
            <a href="#" class="contact-link">1800 202 4444</a>
          </div>
          <div class="social-icon">
            <img src="https://www.lenskart.com/stores/svg/facebook.svg" alt="Facebook" />
          </div>
          <div class="social-icon">
            <img src="https://www.lenskart.com/stores/svg/instagram.svg" alt="Instagram" />
          </div>
          <div class="social-icon">
            <img src="https://www.lenskart.com/stores/svg/youtube.svg" alt="Youtube" />
          </div>
          <div class="social-icon">
            <img src="https://www.lenskart.com/stores/svg/web.svg" alt="Website" />
          </div>
        </div>
      </div>
    </div>

  );
}

export default Header;
