import axios from "../axiosConfig"; // Adjust the import path accordingly

//const express_server_url = process.env.REACT_APP_SERVER_URL;
const express_server_url = window.SERVER_CONFIG.REACT_APP_SERVER_URL;
const dotnet_server_url = window.SERVER_CONFIG.REACT_APP_DOTNETSERVER_URL;

var WebApiUrl = express_server_url;
WebApiUrl = express_server_url.replace("localhost", window.location.hostname);

const PayStack = {

  CreatePayment: async (data) => {
    try {
      const apiUrl = `${WebApiUrl}/api/PayStack/CreatePayment`;
      const response = await axios.post(
        apiUrl,
       data,
        {
            withCredentials: true,
            credentials: "include",
        }
      );
      
      console.log(response);
      return response;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },



  VerifyPayment: async (data) => {
    try {
        const apiUrl = `${WebApiUrl}/api/PayStack/VerifyPayment`;
      const response = await axios.post(apiUrl, 
       data,
       {
        withCredentials: true,
      });
      console.log("Response:", response);
      return response;
    } catch (error) {
      console.error("Error:", error.message);
    }
  },
  



  

};
export default PayStack;
