import React, { useEffect, useState } from "react";
import ProductCart from "../../Component/Card/ProductCart";
import styletype from  "../../Assets/Icons/glasses (1).png";
import styletype1 from "../../Assets/Icons/glasses.png";
import styletype2 from "../../Assets/Icons/hexa.png";
import styletype3 from "../../Assets/Icons/oval-shape-reading-eyeglasses.png";
import styletype4 from "../../Assets/Icons/Square.webp";
import styletype5 from "../../Assets/Icons/Wayfarer.webp";
import styletype6 from "../../Assets/Icons/Rectangle.webp";
import styletype7 from "../../Assets/Icons/sun-glasses.png";
import styletype8 from "../../Assets/Icons/Geometric.webp";
import styletype9 from "../../Assets/Icons/Clubmaster.webp";
import styletype10 from "../../Assets/Icons/Sports.webp";
import { useSpring, animated } from "react-spring";
import grid from "../../Assets/Icons/list.png";
import grid1 from "../../Assets/Icons/visualization.png";
import MobileFilterPage from "../../Component/Modal/MobileFilterPage";
import ProductCart1 from "../../Component/Card/ProductCart1";
import ProductCart2 from "../../Component/Card/ProductCart2";
import ProductCart3 from "../../Component/Card/ProductCart3";
import ProductCart4 from "../../Component/Card/ProductCart4";
import img from "../../Assets/Images/pic7.png";


const ProductSearchWithBanner = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [price, setPrice] = useState(false);
  const [shopFor, setShopFor] = useState(false);
  const [color, setColor] = useState(false);
  const [style, setStyle] = useState(false);
  const [shape, setShape] = useState(false);
  const [size, setSize] = useState(false);
  const [material, setMaterial] = useState(false);
  const [changegrid, setChangegrid] = useState(false);

  const changeprductgrid = () => {
    setChangegrid(!changegrid);
    const elements = document.getElementsByClassName("card-product");

    const elements1 = document.getElementsByClassName("Card-product-type-1");
    for (const element of elements) {
      element.classList.toggle("cart-product-for-two-row");
    }

    for (const element1 of elements1) {
      element1.classList.toggle("cart-product-for-two-row");
    }
  };

  const fadeAnimation = useSpring({
    opacity: price ? 1 : 0,
    height: price ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation1 = useSpring({
    opacity: shopFor ? 1 : 0,
    height: shopFor ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation2 = useSpring({
    opacity: color ? 1 : 0,
    height: color ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation3 = useSpring({
    opacity: style ? 1 : 0,
    height: style ? 80 : 0,
    config: { duration: 200 },
  });

  // const fadeAnimation4 = useSpring({
  //   opacity: shape ? 1 : 0,
  //   height: shape ? 80 : 0,
  //   config: { duration: 200 },
  // });

  const fadeAnimation5 = useSpring({
    opacity: size ? 1 : 0,
    height: size ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation6 = useSpring({
    opacity: material ? 1 : 0,
    height: material ? 80 : 0,
    config: { duration: 200 },
  });

  const showoption = () => {
    setPrice(!price);
    // setColor(false);
    // setShopFor(false);
    // setStyle(false);
    // setShape(false);
    // setSize(false);
    // setMaterial(false);
  };
  const showoption1 = () => {
    setShopFor(!shopFor);
    // setPrice(false);
    // setColor(false);
    // setStyle(false);
    // setShape(false);
    // setSize(false);
    // setMaterial(false);
  };
  const showoption2 = () => {
    setColor(!color);
    // setPrice(false);
    // setShopFor(false);
    // setStyle(false);
    // setShape(false);
    // setSize(false);
    // setMaterial(false);
  };
  const showoption3 = () => {
    setStyle(!style);
    // setPrice(false);
    // setColor(false);
    // setShopFor(false);
    // setShape(false);
    // setSize(false);
    // setMaterial(false);
  };
  const showoption4 = () => {
    setShape(!shape);
    // setPrice(false);
    // setColor(false);
    // setShopFor(false);
    // setStyle(false);
    // setSize(false);
    // setMaterial(false);
  };
  const showoption5 = () => {
    setSize(!size);
    // setPrice(false);
    // setColor(false);
    // setShopFor(false);
    // setStyle(false);
    // setShape(false);
    // setMaterial(false);
  };
  const showoption6 = () => {
    setMaterial(!material);
    // setPrice(false);
    // setColor(false);
    // setShopFor(false);
    // setStyle(false);
    // setShape(false);
    // setSize(false);
  };

  const [mobileFilter, setMobileFilter] = useState(false);

  const openmobilefilter = () => {
    setMobileFilter(!mobileFilter);
  };

  return (
<div>
    <div>
      <img className="Category-banner" src={img} alt=""></img>
    </div>
    <div className="ProductSearch">
      <div className="ProductSearch-left">
        <div className="ProductSearch-top">
          <p>Filters</p>
        </div>
        <div className="productsearch-bottom">
          <div className="filter-heading" onClick={showoption}>
            Price-Range
            {price ? (
              <i className="fa-solid fa-caret-up option-icon1"></i>
            ) : (
              <i className="fa-solid fa-sort-down option-icon"></i>
            )}
          </div>
          {price && (
            <animated.div style={fadeAnimation} className="filter-options">
              {" "}
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" />
                <p style={{ margin: "0" }}>0-1000 Rs</p>
              </div>
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" name="" id="" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" name="" id="" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
            </animated.div>
          )}

          <div className="filter-heading" onClick={showoption1}>
            {" "}
            Shop For
            {shopFor ? (
              <i className="fa-solid fa-caret-up option-icon1"></i>
            ) : (
              <i className="fa-solid fa-sort-down option-icon"></i>
            )}
          </div>
          {shopFor && (
            <animated.div style={fadeAnimation1} className="filter-options">
              {" "}
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" name="" id="" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" name="" id="" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
            </animated.div>
          )}

          <div className="filter-heading" onClick={showoption4}>
            {" "}
            Shape
            {shape ? (
              <i className="fa-solid fa-caret-up option-icon1"></i>
            ) : (
              <i className="fa-solid fa-sort-down option-icon"></i>
            )}
          </div>
          {shape && (
            <animated.div className="filter-options">
              <div className="style-filter-box">
                <div className="style-filter-1">
                  {" "}
                  <img
                    className="filter-type-image"
                    src={styletype}
                    alt=""
                  />{" "}
                  <p>ROUND</p>
                </div>
                <div className="style-filter-1">
                  {" "}
                  <img
                    className="filter-type-image"
                    src={styletype1}
                    alt=""
                  />{" "}
                  <p>CATEYE</p>
                </div>
                <div className="style-filter-1">
                  {" "}
                  <img
                    className="filter-type-image"
                    src={styletype2}
                    alt=""
                  />{" "}
                  <p>HEXAGONAL</p>
                </div>
                <div className="style-filter-1">
                  {" "}
                  <img
                    className="filter-type-image"
                    src={styletype3}
                    alt=""
                  />{" "}
                  <p>OVAL</p>
                </div>
                <div className="style-filter-1">
                  {" "}
                  <img
                    className="filter-type-image-1"
                    src={styletype4}
                    alt=""
                  />{" "}
                  <p>SQUARE</p>
                </div>
                <div className="style-filter-1">
                  {" "}
                  <img
                    className="filter-type-image-1"
                    src={styletype5}
                    alt=""
                  />{" "}
                  <p>WAYFARER</p>
                </div>
                <div className="style-filter-1">
                  {" "}
                  <img
                    className="filter-type-image-1"
                    src={styletype6}
                    alt=""
                  />{" "}
                  <p>RECTANGLE</p>
                </div>
                <div className="style-filter-1">
                  {" "}
                  <img
                    className="filter-type-image"
                    src={styletype7}
                    alt=""
                  />{" "}
                  <p>AVIATOR</p>
                </div>
                <div className="style-filter-1">
                  {" "}
                  <img
                    className="filter-type-image-1"
                    src={styletype8}
                    alt=""
                  />{" "}
                  <p>GEOMATRIC</p>
                </div>
                <div className="style-filter-1">
                  {" "}
                  <img
                    className="filter-type-image-1"
                    src={styletype9}
                    alt=""
                  />{" "}
                  <p>CLUBMASTER</p>
                </div>
                <div className="style-filter-1">
                  {" "}
                  <img
                    className="filter-type-image-1"
                    src={styletype10}
                    alt=""
                  />{" "}
                  <p>SPORTS</p>
                </div>
              </div>
            </animated.div>
          )}

          <div className="filter-heading" onClick={showoption2}>
            {" "}
            Color
            {color ? (
              <i className="fa-solid fa-caret-up option-icon1"></i>
            ) : (
              <i className="fa-solid fa-sort-down option-icon"></i>
            )}
          </div>
          {color && (
            <animated.div style={fadeAnimation2} className="filter-options">
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" name="" id="" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" name="" id="" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
            </animated.div>
          )}

          <div className="filter-heading" onClick={showoption3}>
            Styles
            {style ? (
              <i className="fa-solid fa-caret-up option-icon1"></i>
            ) : (
              <i className="fa-solid fa-sort-down option-icon"></i>
            )}
          </div>
          {style && (
            <animated.div style={fadeAnimation3} className="filter-options">
              {" "}
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" name="" id="" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" name="" id="" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
            </animated.div>
          )}

          <div className="filter-heading" onClick={showoption5}>
            {" "}
            Size
            {size ? (
              <i className="fa-solid fa-caret-up option-icon1"></i>
            ) : (
              <i className="fa-solid fa-sort-down option-icon"></i>
            )}
          </div>
          {size && (
            <animated.div style={fadeAnimation5} className="filter-options">
              {" "}
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" name="" id="" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" name="" id="" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
            </animated.div>
          )}

          <div className="filter-heading" onClick={showoption6}>
            {" "}
            Material
            {material ? (
              <i className="fa-solid fa-caret-up option-icon1"></i>
            ) : (
              <i className="fa-solid fa-sort-down option-icon"></i>
            )}
          </div>
          {material && (
            <animated.div style={fadeAnimation6} className="filter-options">
              {" "}
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" name="" id="" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
              <div className="filter-line">
                <input type="checkbox" className="ui-checkbox" name="" id="" />
                <p style={{ margin: "0" }}>filter-name</p>
              </div>
            </animated.div>
          )}
        </div>
      </div>
      <div className="ProductSearch-right">
        <div className="ProductSearch-top">
          <p>Products</p>
          
          <select name="" id="" className="filter-select">
            <option value="">Sort by</option>
            <option value="">Most populer</option>
            <option value="">Price low to high</option>
            <option value="">Price high to low </option>
            <option value="">Customer Reviews</option>
            <option value="">Newly Added</option>
          </select>
        </div>
        <div className="ProductSearch-section">
          <ProductCart1 />
          <ProductCart1 />
          <ProductCart1 />
          <ProductCart2 />
          <ProductCart2 />
          <ProductCart2 />
          <ProductCart3 />
          <ProductCart3 />
          <ProductCart3 />
          <ProductCart4 />
          <ProductCart4 />
          <ProductCart4 />

          <ProductCart />

          <ProductCart />

          <ProductCart />

          <ProductCart />

          <ProductCart />

          <ProductCart />

          <ProductCart />

          <ProductCart />
        </div>
      </div>

      {mobileFilter && <MobileFilterPage close={openmobilefilter} />}

      <button onClick={openmobilefilter} className="filter-mobile-btn">
        {" "}
        <i
          style={{ marginRight: "10px" }}
          className="fa-solid fa-filter"
        ></i>{" "}
        Filter{" "}
      </button>
    </div>
    </div>
  );
};

export default ProductSearchWithBanner;
