import { useEffect, useRef, useCallback } from 'react';

const useIntersectionObserver = (callback, options) => {
    const elementRef = useRef(null);
    const observerRef = useRef(null);

    useEffect(() => {
        if (observerRef.current) observerRef.current.disconnect();

        // Ensure callback is memoized to prevent unnecessary re-renders
        observerRef.current = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    callback(entry.target);
                }
            });
        }, options);

        if (elementRef.current) observerRef.current.observe(elementRef.current);

        return () => {
            if (observerRef.current) observerRef.current.disconnect();
        };
    }, [callback, options]); // Re-initialize observer only if callback or options change

    return elementRef;
};

export default useIntersectionObserver;
