import { useState, useEffect } from 'react';

function useGeolocation() {
  const [position, setPosition] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const { latitude, longitude } = pos.coords;
          setPosition({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error('Error getting geolocation: ', error);
          setPosition({ lat: 47.2178, lng: -1.55336 });
        }
      );
    } else {
      console.error('Geolocation not supported by this browser.');
      setPosition({ lat: 47.2178, lng: -1.55336 }); 
    }
  }, []);

  return { position };
}

export default useGeolocation;
