import React, { useEffect,useState } from 'react'
import LogOut from "../../Component/LogOut";
import AlertBox from '../AlertBox/AlertBox';
import Account from '../../../API/API_SERVISES/Account';
import Loader1 from '../Loaders/Loader1';
import { useNavigate } from "react-router-dom";

const MyProfile = (props) => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alert1 , setAlert1] = useState(false);
  const alertbox =()=>{
    setAlert1(!alert1)
  }


  const [deletebutton, setDeletebutton] = useState(false);
  const deletemodal = async () => {
    setDeletebutton(!deletebutton);
  };
  const closeModal = () => {
    setDeletebutton(false);
  }
  const DeleteAccount = async () => {
    const storedArrayString = localStorage.getItem('accountLogin');
    const loginUser = JSON.parse(storedArrayString);

    await Account.deleteAccount(loginUser.d1Id, loginUser.d2Id);
    localStorage.removeItem("accountLogin");
    localStorage.removeItem("OrderDataToTrack");
    localStorage.removeItem("Address");
    alert("Deleted SuccessFully");
    navigate("/");
  }


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const [inputValues, setInputValues] = useState({
    name: "",
    mobileNo: "",
    email: "",
    dob: "",
    password: "",
  });

  useEffect(() => {
    // Check if props.accountData exists and has a value
    if (props.accountData && Object.keys(props.accountData).length > 0) {
      setInputValues({
        name: props.accountData.name,
        mobileNo: props.accountData.mobileNo,
        email: props.accountData.email,
        dob: props.accountData.dob,
        password: props.accountData.password,
      });
    }
  }, [props.accountData]);



  const UpdateAccountData = async () => {
    setLoading(true)
    const filteredData = [
      {
        "operationType": 0,
        "path": "password",
        "op": "Add",
        "from": "string",
        "value": inputValues.password
      },
      {
        "operationType": 0,
        "path": "Name",
        "op": "Add",
        "from": "string",
        "value": inputValues.name
      },
      {
        "operationType": 0,
        "path": "AMobileNo",
        "op": "Add",
        "from": "string",
        "value": inputValues.mobileNo
      },
      {
        "operationType": 0,
        "path": "AEmail",
        "op": "Add",
        "from": "string",
        "value": inputValues.email
      },
      {
        "operationType": 0,
        "path": "ADob",
        "op": "Add",
        "from": "string",
        "value": inputValues.dob
      },
    ]
    await Account.PartiallyUpdateAccount(filteredData);
     setLoading(false);
    alert("Updated")
  };


  return (
    



    <div className='admin-my-profile'>

<div
          style={{  borderRadius: "15px" }}
          className="card-cart cart height"
        >
          <label className="title">Account Information</label>

          <div className="pi-main-box">
            <div className="personal-info-text-box" style={{ display: "flex" }}>
              <div className="fs-13" >Name :</div>
              <div className="form1">
                <input
                  className="input2"
                  placeholder="Name"
                  required=""
                  type="text"
                  value={inputValues.name}
                  onChange={handleInputChange}
                  name="name"
                />
                <span className="input-border1"></span>
              </div>
            </div>
            <div className="personal-info-text-box" style={{ display: "flex" }}>
              <div className="fs-13">Mobile No :</div>
              <div className="form1">
                <input
                  className="input2"
                  placeholder="Mobile no"
                  required=""
                  type="number"
                  value={inputValues.mobileNo}
                  onChange={handleInputChange}
                  name="mobileNo"
                />
                <span className="input-border1"></span>
              </div>
            </div>
            <div className="personal-info-text-box" style={{ display: "flex" }}>
              <div className="fs-13">E-Mail :</div>
              <div className="form1">
                <input
                  className="input2"
                  placeholder="E-Mail"
                  required=""
                  type="text"
                  value={inputValues.email}
                  onChange={handleInputChange}
                  name="email"
                />
                <span className="input-border1"></span>
              </div>
            </div>
            <div className="personal-info-text-box" style={{ display: "flex" }}>
              <div className="fs-13">Date of birth :</div>
              <div className="form1">
                <input
                id="session-date" 
                  className="input2"
                  placeholder="Name"
                  required=""
                  type="date"
                  onChange={handleInputChange}
                  value={inputValues.dob}
                  name="dob"
                  // onClick={toggleDatePicker}
                />
                <span className="input-border1"></span>
              </div>
            </div>
            <div className="personal-info-text-box" style={{ display: "flex" }}>
              <div className="fs-13">Password :</div>
              <div className="form1">
                <input
                  className="input2"
                  placeholder="password"
                  required=""
                  type="password"
                  value={inputValues.password}
                  onChange={handleInputChange}
                  name="password"
                />
                <span className="input-border1"></span>
              </div>
            </div>
            <div className="button-box-pi">
                <button className="button-pi"  onClick={UpdateAccountData}>Update</button>

                {/* <button onClick={deletemodal} style={{background:"rgb(207 12 12)"}} className="button-pi">Delete</button> */}
            </div>

          </div>
        </div>
      {deletebutton && (
        <AlertBox alertname="Are You Sure You Want to Delete Your Account" alertbox={DeleteAccount}  close={closeModal}/>
      )}
       {loading && <Loader1 />}
    </div>
  )
}

export default MyProfile